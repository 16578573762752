import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import "./App.css";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import MainPage from "./components/MainPage";
import About from "./components/About";
import Portfolio from "./components/Portfolio";
import Process from "./components/Process";
import Store from "./components/Store";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Scrollbar from "./components/Scrollbar";
import WorkGallery from "./pages/WorkGallery";
import FullpageLayout from "./components/FullpageLayout";
import ScrollContainer from "./components/ScrollContainer";

function App() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activeSection, setActiveSection] = useState("section1"); // Initialize with the default section

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const [manualScrollActiveSection, setManualScrollActiveSection] =
    useState("section1");

  // Add a scroll event listener
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;

      // Determine which section is currently in the viewport
      const sections = [
        "section1",
        "section2",
        "section3",
        "section4",
        "section5",
        "section6",
      ];

      let active = "section1"; // Default to section1 if no other section is in view

      sections.forEach((section) => {
        const sectionElement = document.getElementById(section);
        if (sectionElement) {
          const sectionTop = sectionElement.offsetTop;
          const sectionBottom = sectionTop + sectionElement.offsetHeight;

          if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
            active = section;
          }
        }
      });

      // Update the state variable based on the scroll position
      setManualScrollActiveSection(active);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      // Remove the scroll event listener when the component unmounts
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Get the current location using useLocation
  const location = useLocation();

  // Check if the current route is "/workgallery"
  const isWorkGalleryPage = location.pathname === "/workgallery";

  return (
    <div className="App">
      <ScrollContainer />
      <div>
        <Header
          toggleSidebar={toggleSidebar}
          activeSection={manualScrollActiveSection}
          // setActiveSection={setActiveSection}
        />
        {isSidebarOpen && (
          <Sidebar
            activeSection={manualScrollActiveSection}
            setActiveSection={setActiveSection}
            toggleSidebar={toggleSidebar}
          />
        )}
        <Routes>
          <Route path="/" element={<FullpageLayout />} />
          <Route path="/workgallery" element={<WorkGallery />} />
        </Routes>
        <Footer id="footer" />

        {/* <Scrollbar
          activeSection={manualScrollActiveSection}
          setActiveSection={setActiveSection}
        /> */}
        {!isWorkGalleryPage && (
          <Scrollbar
            activeSection={manualScrollActiveSection}
            setActiveSection={setActiveSection}
          />
        )}
      </div>
    </div>
  );
}

export default App;
