// Section.js
import React, { useState, useRef } from "react";
import "../static/fonts/font.css";
import { useMediaQuery } from "react-responsive";
import Alert from "./Modal";
import emailjs from "@emailjs/browser";

const Contact = ({ id }) => {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const isTablet = useMediaQuery({ minWidth: 481, maxWidth: 1024 });
  const isBrowser = useMediaQuery({ minWidth: 1024 });

  const sectionStyle = {
    width: "100.0000vw",
    height: isTablet ? "66vh" : "100.0000vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F2F3F5",
  };

  const text1 = {
    position: "absolute",
    width: isMobile ? "" : isTablet ? "" : "2.9688vw",
    height: isMobile ? "1.8519vh" : isTablet ? "1.8519vh" : "1.8519vh",
    left: isMobile ? "45vw" : isTablet ? "9.3750vw" : "9.3750vw",
    top: isMobile ? "511vh" : isTablet ? "336vh" : "512.2222vh",
    opacity: "1.00",
    fontFamily: "Pretendard Variable",
    fontSize: isMobile ? "3.1250vw" : isTablet ? "1.5vw" : "0.8333vw",
    fontWeight: "500",
    textAlign: isMobile ? "center" : "left",
    color: "#2A51EC",
  };

  const text2 = {
    position: "absolute",
    width: isMobile ? "" : isTablet ? "" : "11.7188vw",
    height: isMobile ? "6.3889vh" : isTablet ? "6.3889vh" : "6.3889vh",
    left: isMobile ? "30.5vw" : isTablet ? "9.3750vw" : "9.3750vw",
    top: isMobile ? "510vh" : isTablet ? "336vh" : "510.1852vh",
    opacity: "1.00",
    fontFamily: "SCoreDream",
    fontSize: isMobile ? "9.3750vw" : isTablet ? "4vw" : "3.1250vw",
    fontWeight: "900",
    textAlign: isMobile ? "center" : "left",
    color: "#111111",
  };

  const text3 = {
    position: "absolute",
    width: isMobile ? "" : isTablet ? "30vw" : "18vw",
    height: isMobile ? "1.8519vh" : "1.8519vh",
    left: isMobile ? "7vw" : isTablet ? "3vw" : "2vw",
    top: isMobile ? "0.4vh" : isTablet ? "0.1vh" : "0.2vh",
    opacity: "1.00",
    fontFamily: "Pretendard Variable",
    fontSize: isMobile ? "3.1250vw" : isTablet ? "2vw" : "0.8333vw",
    fontWeight: "500",
    textAlign: isMobile ? "center" : "left",
  };

  const text4 = {
    position: "absolute",
    width: "4.5313vw",
    height: "1.8519vh",
    left: isMobile ? "10vw" : isTablet ? "53vw" : "9.3750vw",
    top: isMobile ? "577vh" : isTablet ? "371vh" : "574.7222vh",
    opacity: "1.00",
    fontFamily: "Pretendard Variable",
    fontSize: isMobile ? "2.8333vw" : isTablet ? "1.5vw" : "0.8333vw",
    fontWeight: "bold",
    textAlign: "left",
  };

  const text5 = {
    position: "absolute",
    width: "4.5313vw",
    height: "1.8519vh",
    left: isMobile ? "10vw" : isTablet ? "53vw" : "9.3750vw",
    top: isMobile ? "579.5000vh" : isTablet ? "373vh" : "577.5000vh",
    opacity: "1.00",
    fontFamily: "Pretendard Variable",
    fontSize: isMobile ? "2.8333vw" : isTablet ? "1.5vw" : "0.8333vw",
    fontWeight: "bold",
    textAlign: "left",
  };

  const text6 = {
    position: "absolute",
    width: "4.5313vw",
    height: "1.8519vh",
    left: isMobile ? "10vw" : isTablet ? "53vw" : "9.3750vw",
    top: isMobile ? "582vh" : isTablet ? "375vh" : "580.2778vh",
    opacity: "1.00",
    fontFamily: "Pretendard Variable",
    fontSize: isMobile ? "2.8333vw" : isTablet ? "1.5vw" : "0.8333vw",
    fontWeight: "bold",
    textAlign: "left",
  };

  const text7 = {
    position: "absolute",
    height: "1.8519vh",
    left: isMobile ? "10vw" : isTablet ? "53vw" : "9.3750vw",
    top: isMobile ? "584.5000vh" : isTablet ? "377vh" : "583.0556vh",
    opacity: "1.00",
    fontFamily: "Pretendard Variable",
    fontSize: isMobile ? "2.8333vw" : isTablet ? "1.5vw" : "0.8333vw",
    fontWeight: "bold",
    textAlign: "left",
  };

  const text8 = {
    position: "absolute",
    height: "1.8519vh",
    left: isMobile ? "10vw" : isTablet ? "53vw" : "9.3750vw",
    top: isMobile ? "587vh" : isTablet ? "379vh" : "585.8333vh",
    opacity: "1.00",
    fontFamily: "Pretendard Variable",
    fontSize: isMobile ? "2.8333vw" : isTablet ? "1.5vw" : "0.8333vw",
    fontWeight: "bold",
    textAlign: "left",
  };

  const text9 = {
    position: "absolute",
    // width: "12.9167vw",
    height: "1.8519vh",
    left: isMobile ? "" : isTablet ? "" : "24.0104vw",
    right: isMobile ? "10vw" : isTablet ? "5vw" : "",
    top: isMobile ? "577vh" : isTablet ? "371vh" : "574.7222vh",
    opacity: "1.00",
    fontFamily: "Pretendard Variable",
    fontSize: isMobile ? "2.8333vw" : isTablet ? "1.5vw" : "0.8333vw",
    fontWeight: "500",
    textAlign: "left",
  };

  const text10 = {
    position: "absolute",
    // width: "12.9167vw",
    height: "1.8519vh",
    left: isMobile ? "" : isTablet ? "" : "24.0104vw",
    right: isMobile ? "10vw" : isTablet ? "5vw" : "",
    top: isMobile ? "579.5000vh" : isTablet ? "373vh" : "577.5000vh",
    opacity: "1.00",
    fontFamily: "Pretendard Variable",
    fontSize: isMobile ? "2.8333vw" : isTablet ? "1.5vw" : "0.8333vw",
    fontWeight: "500",
    textAlign: "left",
  };

  const text11 = {
    position: "absolute",
    // width: "12.9167vw",
    height: "1.8519vh",
    left: isMobile ? "" : isTablet ? "" : "24.0104vw",
    right: isMobile ? "10vw" : isTablet ? "5vw" : "",
    top: isMobile ? "582vh" : isTablet ? "375vh" : "580.2778vh",
    opacity: "1.00",
    fontFamily: "Pretendard Variable",
    fontSize: isMobile ? "2.8333vw" : isTablet ? "1.5vw" : "0.8333vw",
    fontWeight: "500",
    textAlign: "left",
  };

  const text12 = {
    position: "absolute",
    // width: "12.9167vw",
    height: "1.8519vh",
    left: isMobile ? "" : isTablet ? "" : "24.0104vw",
    right: isMobile ? "10vw" : isTablet ? "5vw" : "",
    top: isMobile ? "584.5000vh" : isTablet ? "377vh" : "583.0556vh",
    opacity: "1.00",
    fontFamily: "Pretendard Variable",
    fontSize: isMobile ? "2.8333vw" : isTablet ? "1.5vw" : "0.8333vw",
    fontWeight: "500",
    textAlign: "left",
  };

  const text13 = {
    position: "absolute",
    // width: "12.9167vw",
    height: "1.8519vh",
    left: isMobile ? "" : isTablet ? "" : "24.0104vw",
    right: isMobile ? "10vw" : isTablet ? "5vw" : "",
    top: isMobile ? "587vh" : isTablet ? "379vh" : "585.8333vh",
    opacity: "1.00",
    fontFamily: "Pretendard Variable",
    fontSize: isMobile ? "2.8333vw" : isTablet ? "1.5vw" : "0.8333vw",
    fontWeight: "500",
    textAlign: "left",
  };

  const line1 = {
    position: "absolute",
    width: isMobile ? "80vw" : isTablet ? "90vw" : "36.8750vw",
    height: "0.0521vw",
    left: isMobile ? "10vw" : isTablet ? "5vw" : "0.0521vw",
    top: isMobile ? "520.4444vh" : isTablet ? "343vh" : "524.4444vh",
    opacity: "1.00",
    backgroundColor: "#A9AFBC",
  };

  const line2 = {
    position: "absolute",
    width: isMobile ? "80vw" : isTablet ? "42.5vw" : "28.0000vw",
    height: "0.0521vw",
    left: isMobile ? "10vw" : isTablet ? "52.5vw" : "9.3750vw",
    top: isMobile ? "577vh" : isTablet ? "383vh" : "574.2593vh",
    opacity: "1.00",
    backgroundColor: "#C1C1C1",
  };

  const input1 = {
    position: "absolute",
    width: isMobile ? "80vw" : isTablet ? "42.5vw" : "27.5000vw",
    height: isMobile ? "4vh" : isTablet ? "4.8148vh" : "4.8148vh",
    left: isMobile ? "10vw" : isTablet ? "5vw" : "9.3750vw",
    top: isMobile ? "522vh" : isTablet ? "344vh" : "526.2037vh",
    opacity: "1.00",
    borderColor: "#a9afbc",
    borderWidth: "0.0521vw",
    borderStyle: "solid",
    borderRadius: isMobile ? "3vw" : isTablet ? "3vw" : "0.9375vw",
    textIndent: isMobile ? "3vw" : isTablet ? "3vw" : "1vw",
    textAlign: isMobile ? "left" : "left",
    backgroundColor: "transparent",
  };

  const input2 = {
    position: "absolute",
    width: isMobile ? "80vw" : isTablet ? "42.5vw" : "27.5000vw",
    height: isMobile ? "4vh" : isTablet ? "4.8148vh" : "4.8148vh",
    left: isMobile ? "10vw" : isTablet ? "52.5vw" : "9.3750vw",
    top: isMobile ? "528vh" : isTablet ? "344vh" : "532.4074vh",
    opacity: "1.00",
    borderColor: "#a9afbc",
    borderWidth: "0.0521vw",
    borderStyle: "solid",
    borderRadius: isMobile ? "3vw" : isTablet ? "3vw" : "0.9375vw",
    textIndent: isMobile ? "3vw" : isTablet ? "3vw" : "1vw",
    textAlign: isMobile ? "left" : "left",
    backgroundColor: "transparent",
  };

  const input3 = {
    position: "absolute",
    width: isMobile ? "80vw" : isTablet ? "42.5vw" : "27.5000vw",
    height: isMobile ? "4vh" : isTablet ? "4.8148vh" : "4.8148vh",
    left: isMobile ? "10vw" : isTablet ? "5vw" : "9.3750vw",
    top: isMobile ? "534vh" : isTablet ? "350vh" : "538.7037vh",
    opacity: "1.00",
    borderColor: "#a9afbc",
    borderWidth: "0.0521vw",
    borderStyle: "solid",
    borderRadius: isMobile ? "3vw" : isTablet ? "3vw" : "0.9375vw",
    textIndent: isMobile ? "3vw" : isTablet ? "3vw" : "1vw",
    textAlign: isMobile ? "left" : "left",
    backgroundColor: "transparent",
  };

  const input4 = {
    position: "absolute",
    width: isMobile ? "80vw" : isTablet ? "42.5vw" : "27.5000vw",
    height: isMobile ? "4vh" : isTablet ? "4.8148vh" : "4.8148vh",
    left: isMobile ? "10vw" : isTablet ? "52.5vw" : "9.3750vw",
    top: isMobile ? "540vh" : isTablet ? "350vh" : "544.9074vh",
    opacity: "1.00",
    borderColor: "#a9afbc",
    borderWidth: "0.0521vw",
    borderStyle: "solid",
    borderRadius: isMobile ? "3vw" : isTablet ? "3vw" : "0.9375vw",
    textIndent: isMobile ? "3vw" : isTablet ? "3vw" : "1vw",
    textAlign: isMobile ? "left" : "left",
    backgroundColor: "transparent",
  };

  const textareaStyle = {
    position: "absolute",
    width: isMobile ? "80vw" : isTablet ? "42.5vw" : "27.5000vw",
    height: isMobile ? "8vh" : isTablet ? "30vh" : "16.0000vh",
    left: isMobile ? "10vw" : isTablet ? "5vw" : "9.3750vw",
    top: isMobile ? "546vh" : isTablet ? "356vh" : "551.1111vh",
    opacity: "1.00",
    borderColor: "#a9afbc",
    borderWidth: "0.0521vw",
    borderStyle: "solid",
    borderRadius: isMobile ? "3vw" : isTablet ? "3vw" : "0.9375vw",
    textIndent: isMobile ? "3vw" : isTablet ? "3vw" : "1vw",
    textAlign: isMobile ? "left" : "left",
    paddingTop: "2vh",
    backgroundColor: "transparent",
  };

  const checkboxStyle = {
    position: "absolute",
    width: isMobile ? "4vw" : isTablet ? "2vw" : "0.8333vw",
    height: isMobile ? "4vw" : isTablet ? "2vw" : "0.8333vw",
    opacity: "1.00",
  };

  const checkboxGroupStyle = {
    position: "absolute",
    width: isMobile ? "54vw" : "18vw",
    height: isMobile ? "6vw" : isTablet ? "6vw" : "1.2vw",
    left: isMobile ? "10vw" : isTablet ? "52.5vw" : "9.3750vw",
    top: isMobile ? "573.7vh" : isTablet ? "385vh" : "570.7vh",
    backgroundColor: "transparent",
  };

  const buttonStyle = {
    position: "absolute",
    width: isMobile ? "15vw" : isTablet ? "8.3333vw" : "8.3333vw",
    height: isMobile ? "7.5000vw" : isTablet ? "3.7037vh" : "3.7037vh",
    left: isMobile ? "77.3417vw" : isTablet ? "87.5vw" : "29.3417vw",
    top: isMobile ? "573.0926vh" : isTablet ? "384vh" : "570.0926vh",
    opacity: "1.00",
    backgroundColor: "#2A51EC",
    borderRadius: isMobile ? "4vw" : isTablet ? "1.3021vw" : "1.3021vw",
    color: "#F2F3F5",
    fontFamily: "Pretendard Variable",
    fontSize: isMobile ? "3.1250vw" : isTablet ? "1.5vw" : "0.8333vw",
    fontWeight: "500",
    lineHeight: "1.4583vw",
    fontStyle: "normal",
    border: "none",
  };

  const boxStyle = {
    position: "absolute",
    width: isMobile ? "82vw" : isTablet ? "42.5vw" : "52.1875vw",
    height: isMobile ? "15vh" : isTablet ? "15vh" : "75.9259vh",
    left: isMobile ? "10vw" : isTablet ? "52.5vw" : "38.4375vw",
    top: isMobile ? "557.5000vh" : isTablet ? "356vh" : "513.9815vh",
    opacity: "1.00",
    backgroundColor: "#C1C1C1",
  };

  const pageGroup = {
    position: "absolute",
    width: isMobile ? "18vw" : "5.7292vw",
    height: isMobile ? "18vw" : "5.7292vw",
    left: isMobile ? "80vw" : "92.1875vw",
    top: isMobile ? "590vh" : isTablet ? "390vh" : "585vh",
    backgroundColor: "transparent",
  };

  const page1 = {
    position: "absolute",
    width: isMobile ? "3.5417vw" : "3.5417vw",
    height: isMobile ? "3.5938vw" : "6.2963vh",
    top: isMobile ? "-3.5vh" : "-4.1667vw",
    opacity: "1.00",
    fontFamily: "Pretendard Variable",
    fontSize: isMobile ? "9.3750vw" : "3.1250vw",
    fontWeight: isMobile ? "900" : "500",
    fontStyle: "normal",
    textAlign: "center",
    lineHeight: "4.8438vw",
    color: "#111111",
  };

  const page2 = {
    position: "absolute",
    width: isMobile ? "2.3958vw" : "3.5417vw",
    height: isMobile ? "2.4479vw" : "6.2963vh",
    left: isMobile ? "9vw" : "2.4vw",
    top: isMobile ? "3vh" : "0vh",
    opacity: "0.20",
    fontFamily: "Pretendard Variable",
    fontSize: isMobile ? "6.2500vw" : "2.0833vw",
    fontWeight: isMobile ? "900" : "500",
    fontStyle: "normal",
    textAlign: "center",
    lineHeight: "4.8438vw",
    color: "#111111",
  };

  const divider = {
    position: "absolute",
    width: isMobile ? "10vw" : "2.6042vw",
    height: "0.1042vw",
    left: isMobile ? "5vw" : "2vw",
    top: isMobile ? "10vw" : "3vw",
    opacity: "1.00",
    backgroundColor: "#111111",
    transform: "rotate(-45deg)",
  };

  const [formData, setFormData] = useState({
    name: "",
    organization: "",
    phoneNumber: "",
    email: "",
    description: "",
    agree: false,
  });

  const [showAlert, setShowAlert] = useState(false);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    console.log("Sending email..."); // Add this line to check if the function is called.

    emailjs
      .sendForm(
        "service_wbqlvzq",
        "template_l2puu3g",
        form.current,
        "9Bu8EBICN6LUNcQcf"
      )
      .then(
        (result) => {
          setShowAlert(true);
          setFormData({
            name: "",
            organization: "",
            phoneNumber: "",
            email: "",
            description: "",
            agree: false,
          });
        },
        (error) => {
          console.log(error.text);
          alert("이메일이 전송이 실패되었습니다.");
        }
      );
  };

  // Handle input changes and update the form data
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const inputValue = type === "checkbox" ? checked : value;

    setFormData({
      ...formData,
      [name]: inputValue,
    });
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here, you can send the formData to your backend or perform other actions
    // window.alert("Request has been Submitted");
    console.log("Form data submitted:", formData);
    setShowAlert(true);
    setFormData({
      name: "",
      organization: "",
      phoneNumber: "",
      email: "",
      description: "",
      agree: false,
    });
  };

  return (
    <section id={id} style={sectionStyle}>
      <div>
        <p style={text1}>Contact</p>
      </div>
      <div>
        <p style={text2}>Contact</p>
      </div>
      <div style={line1}></div>

      <form ref={form} onSubmit={sendEmail}>
        <div>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="이름 *"
            value={formData.name}
            onChange={handleInputChange}
            style={input1}
            required
          />
        </div>
        <div>
          <input
            type="text"
            id="organization"
            name="organization"
            placeholder="단체명"
            value={formData.organization}
            onChange={handleInputChange}
            style={input2}
          />
        </div>
        <div>
          <input
            type="tel"
            id="phoneNumber"
            name="phoneNumber"
            placeholder="연락처 *"
            value={formData.phoneNumber}
            onChange={handleInputChange}
            style={input3}
            required
          />
        </div>
        <div>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="이메일 *"
            value={formData.email}
            onChange={handleInputChange}
            style={input4}
            required
          />
        </div>
        <div>
          <textarea
            id="description"
            name="description"
            placeholder="문의 내용 *"
            value={formData.description}
            onChange={handleInputChange}
            style={textareaStyle}
            required
          ></textarea>
        </div>
        <div style={checkboxGroupStyle}>
          <label>
            <input
              type="checkbox"
              name="agree"
              checked={formData.agree}
              onChange={handleInputChange}
              style={checkboxStyle}
              required
            />
            <span style={text3}>개인정보 수집 및 이용에 동의합니다.</span>
          </label>
        </div>
        <button type="submit" style={buttonStyle}>
          제출
        </button>
      </form>
      {showAlert && (
        <Alert
          message="견적 문의 제출이 완료되었습니다."
          onClose={handleCloseAlert}
        />
      )}
      <div style={line2}></div>
      <div style={boxStyle}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3162.8794289660514!2d126.91638927661594!3d37.557904372040916!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c98ddab2dec85%3A0xbb0d5c839e633abb!2z7ISc7Jq47Yq567OE7IucIOuniO2PrOq1rCDsm5Trk5zsu7XrtoHroZw26ri4IDM!5e0!3m2!1sko!2skr!4v1697087471307!5m2!1sko!2skr"
          width="100%"
          height="100%"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <div>
        <p style={text4}>Address</p>
      </div>
      <div>
        <p style={text5}>Email</p>
      </div>
      <div>
        <p style={text6}>Tel</p>
      </div>
      {/* <div>
        <p style={text7}>계좌번호</p>
      </div> */}
      <div>
        <p style={text7}>운영시간</p>
      </div>
      <div>
        <p style={text9}>서울특별시 마포구 월드컵북로6길 82 3층</p>
      </div>
      <div>
        <p style={text10}>ruienminfo@naver.com</p>
      </div>
      <div>
        <p style={text11}>070-4458-3351</p>
      </div>
      {/* <div>
        <p style={text12}>KB국민 12-3456-7890 루이이엔엠</p>
      </div> */}
      <div>
        <p style={text12}>10:00~17:30 (월~금)</p>
      </div>
      <div style={pageGroup}>
        <div>
          <p style={page1}>06</p>
        </div>
        <div>
          <p style={page2}>06</p>
        </div>
        <div style={divider}></div>
      </div>
    </section>
  );
};

export default Contact;
