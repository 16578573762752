import React, { useState, useEffect } from "react";
import FullpageLayout from "./FullpageLayout";
import { Link as ScrollLink, Element } from "react-scroll";

const ScrollContainer = () => {
  // const [page, setPage] = useState(0);
  // const containerElements = Array.from(
  //   document.getElementsByClassName("container")
  // );
  // const lastPage = containerElements.length - 1;

  // const scrollThreshold = 300; // Adjust this value to control the scroll threshold

  // const handleWheel = (e) => {
  //   e.preventDefault();
  //   if (e.deltaY > scrollThreshold) {
  //     setPage((prevPage) => Math.min(prevPage + 1, lastPage));
  //   } else if (e.deltaY < -scrollThreshold) {
  //     setPage((prevPage) => Math.max(prevPage - 1, 0));
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("wheel", handleWheel, { passive: false });
  //   return () => {
  //     window.removeEventListener("wheel", handleWheel);
  //   };
  // }, []);

  // return (
  //   <div className="wrap">
  //     {containerElements.map((container, index) => (
  //       <div className="container" key={index}>
  //         <ScrollLink
  //           to={`section${index + 1}`}
  //           spy={true}
  //           smooth={true}
  //           duration={500}
  //         >
  //           Scroll to Section {index + 1}
  //         </ScrollLink>
  //         <Element name={`section${index + 1}`}>
  //           <FullpageLayout />
  //         </Element>
  //       </div>
  //     ))}
  //   </div>
  // );
};

export default ScrollContainer;
