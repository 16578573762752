import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../static/fonts/font.css";
import ImageUrls from "../components/ImageUrls";
import { useMediaQuery } from "react-responsive";
import { BrowserView, MobileView } from "react-device-detect";

const ImageBox = ({ imageUrl, boxStyle }) => {
  return <div style={boxStyle}></div>;
};

const BackButton = () => {
  const backButtonStyle = {
    position: "absolute",
    left: "7.2917vw",
    top: "11.1111vh",
    // fontSize: "1rem",
    backgroundColor: "transparent", // Button background color
    color: "#111111", // Button text color
    padding: "0.5rem 1rem", // Button padding
    border: "none",
    fontSize: "1.5625vw",
    borderRadius: "0.2083vw",
    cursor: "pointer",
    textDecoration: "none", // Remove underlines from the Link component
  };

  const arrowStyle = {
    fontSize: "1.5625vw",
    marginRight: "0.2604vw", // Add some spacing between the arrow and "back"
  };

  return (
    <Link to="/">
      <button style={backButtonStyle}>
        <span style={arrowStyle}>&#8592;</span>{" "}
        <span
          style={{ fontSize: "1.5000vw", fontFamily: "Pretendard Variable" }}
        >
          back
        </span>
      </button>
    </Link>
  );
};

const WorkGallery = ({ id }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768 });

  const sectionStyle = {
    width: "100.0000vw",
    height: isMobile ? "120vh" : "289.6296vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F2F3F5",
  };

  const desktopStyle = {
    text1: {
      position: "absolute",
      width: "3.1250vw",
      height: "1.8519vh",
      left: "48.4375vw",
      top: "22.2222vh",
      opacity: "1.00",
      color: "#2A51EC",
      fontFamily: "Pretendard Variable",
      fontSize: "1.0471vw",
      fontWeight: "500",
      textAlign: "center",
      lineHeight: "4.8438vw",
    },

    text2: {
      position: "absolute",
      // width: "20.2708vw",
      height: "6.3889vh",
      left: "40.3646vw",
      top: "25.0000vh",
      opacity: "1.00",
      color: "#111111",
      fontFamily: "SCoreDream",
      fontSize: "3.1250vw",
      fontWeight: "700",
      textAlign: "center",
      lineHeight: "4.6875vw",
    },

    leftArrowButtonStyle: {
      position: "relative",
      // left: "43.2292vw",
      top: "125.0000vh",
      fontSize: "2.2500vw", // Adjust the size as needed
      backgroundColor: "transparent",
      border: "none",
      cursor: "pointer",
      outline: "none",
      color: "#111111", // Arrow color
    },

    rightArrowButtonStyle: {
      position: "relative",
      // right: "43.2292vw",
      top: "125.0000vh",
      fontSize: "2.2500vw", // Adjust the size as needed
      backgroundColor: "transparent",
      border: "none",
      cursor: "pointer",
      outline: "none",
      color: "#111111", // Arrow color
    },

    boxPositions: [
      {
        left: "7.2917vw",
        top: "42.5926vh",
      },
      {
        left: "36.0417vw",
        top: "42.5926vh",
      },
      {
        left: "64.7917vw",
        top: "42.5926vh",
      },
      {
        left: "7.2917vw",
        top: "93.8889vh",
      },
      {
        left: "36.0417vw",
        top: "93.8889vh",
      },
      {
        left: "64.7917vw",
        top: "93.8889vh",
      },
      {
        left: "7.2917vw",
        top: "145.1852vh",
      },
      {
        left: "36.0417vw",
        top: "145.1852vh",
      },
      {
        left: "64.7917vw",
        top: "145.1852vh",
      },
      {
        left: "7.2917vw",
        top: "196.4815vh",
      },
      {
        left: "36.0417vw",
        top: "196.4815vh",
      },
      {
        left: "64.7917vw",
        top: "196.4815vh",
      },
    ],
  };

  const mobileStyle = {
    text1: {
      position: "relative",
      // width: "3.1250vw",
      // height: "1.8519vh",
      // left: "48.4375vw",
      top: "-43vh",
      opacity: "1.00",
      color: "#2A51EC",
      fontFamily: "Pretendard Variable",
      fontSize: "3.1250vw",
      fontWeight: "500",
      textAlign: "center",
      // lineHeight: "4.8438vw",
    },

    text2: {
      position: "relative",
      // width: "20.2708vw",
      // height: "6.3889vh",
      // left: "40.3646vw",
      top: "-45vh",
      opacity: "1.00",
      color: "#111111",
      fontFamily: "SCoreDream",
      fontSize: "9.3750vw",
      fontWeight: "900",
      textAlign: "center",
      lineHeight: "4.6875vw",
    },

    leftArrowButtonStyle: {
      position: "relative",
      top: "45.0000vh",
      fontSize: "5vw", // Adjust the size as needed
      backgroundColor: "transparent",
      border: "none",
      cursor: "pointer",
      outline: "none",
      color: "#111111", // Arrow color
    },

    rightArrowButtonStyle: {
      position: "relative",
      top: "45.0000vh",
      fontSize: "5vw", // Adjust the size as needed
      backgroundColor: "transparent",
      border: "none",
      cursor: "pointer",
      outline: "none",
      color: "#111111", // Arrow color
    },

    boxPositions: [
      {
        left: "10.0000vw",
        top: "22vh",
      },
      {
        left: "10.0000vw",
        top: "63vh",
      },
      {
        left: "10.0000vw",
        top: "22vh",
      },
      {
        left: "10.0000vw",
        top: "63vh",
      },
      {
        left: "10.0000vw",
        top: "22vh",
      },
      {
        left: "10.0000vw",
        top: "63vh",
      },
      {
        left: "10.0000vw",
        top: "22vh",
      },
      {
        left: "10.0000vw",
        top: "63vh",
      },
      {
        left: "10.0000vw",
        top: "22vh",
      },
      {
        left: "10.0000vw",
        top: "63vh",
      },
      {
        left: "10.0000vw",
        top: "22vh",
      },
      {
        left: "10.0000vw",
        top: "63vh",
      },
    ],
  };

  // Check if the page number is stored in localStorage and use it as the initial page.
  const storedPage = localStorage.getItem("currentPage");
  const initialPage = storedPage ? parseInt(storedPage) : 1;

  // State variables
  const [currentPage, setCurrentPage] = useState(initialPage);
  const desktopItemsPerPage = 12;
  const mobileItemsPerPage = 2;

  // Calculate the total number of pages based on the view
  const totalItems = isMobile
    ? mobileStyle.boxPositions.length
    : desktopStyle.boxPositions.length;
  const itemsPerPage = isMobile ? mobileItemsPerPage : desktopItemsPerPage;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // Create an array of page numbers from 1 to totalPages
  const pageNumbers = Array.from(
    { length: totalPages },
    (_, index) => index + 1
  );

  // Event handlers for pagination
  const goToPrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    localStorage.setItem("currentPage", currentPage.toString());
  }, [currentPage]);

  // Create an array to store the JSX elements for the boxes
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const itemsToDisplay = isMobile
    ? mobileStyle.boxPositions.slice(startIndex, endIndex)
    : desktopStyle.boxPositions.slice(startIndex, endIndex);

  // Create an array to store the JSX elements for the boxes
  const imageBoxes = itemsToDisplay.map((position, index) =>
    isMobile ? (
      <ImageBox
        key={index}
        imageUrl={ImageUrls[startIndex + index]}
        boxStyle={{
          position: "absolute",
          // width: "38.0000vw",
          // height: "35.0000vh",
          width: "80.0000vw",
          height: "39.0000vh",
          margin: "0.5208vw",
          border: "0.0521vw solid #FFFFFF",
          backgroundImage: `url(${ImageUrls[startIndex + index]})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundColor: "#FFFFFF",
          ...position,
        }}
      />
    ) : (
      <ImageBox
        key={index}
        imageUrl={ImageUrls[startIndex + index]}
        boxStyle={{
          position: "absolute",
          width: "27.9167vw",
          height: "49.6296vh",
          margin: "0.5208vw",
          border: "0.0521vw solid #FFFFFF",
          backgroundImage: `url(${ImageUrls[startIndex + index]})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundColor: "#FFFFFF",
          ...position,
        }}
      />
    )
  );

  return (
    <section id={id} style={sectionStyle}>
      {isMobile ? (
        <MobileView>
          <div>
            <p style={mobileStyle.text1}>Portfolio</p>
          </div>
          <div>
            <p style={mobileStyle.text2}>Work Gallery</p>
          </div>
          <div>{imageBoxes}</div>
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button
                style={mobileStyle.leftArrowButtonStyle}
                onClick={goToPrevPage}
              >
                &lt;
              </button>
              {pageNumbers.map((pageNumber) => (
                <span
                  key={pageNumber}
                  onClick={() => setCurrentPage(pageNumber)}
                  style={{
                    position: "relative",
                    top: "45vh",
                    fontFamily: "Pretendard Variable",
                    fontSize: "5vw",
                    textAlign: "center",
                    marginLeft: "5vw",
                    marginRight: "5vw",
                    opacity: currentPage === pageNumber ? 1 : 0.2,
                    cursor: "pointer",
                  }}
                >
                  {pageNumber}
                </span>
              ))}
              <button
                style={mobileStyle.rightArrowButtonStyle}
                onClick={goToNextPage}
              >
                &gt;
              </button>
            </div>
          </div>
        </MobileView>
      ) : isTablet ? (
        <BrowserView>
          <BackButton />
          <div>
            <p style={desktopStyle.text1}>Portfolio</p>
          </div>
          <div>
            <p style={desktopStyle.text2}>Work Gallery</p>
          </div>
          <div>
            {/* <ImageBox imageUrl="/app/src/assets/workgallery/image1.png" /> */}
            {imageBoxes}
          </div>
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button
                style={desktopStyle.leftArrowButtonStyle}
                onClick={goToPrevPage}
              >
                &lt;
              </button>
              {pageNumbers.map((pageNumber) => (
                <span
                  key={pageNumber}
                  onClick={() => setCurrentPage(pageNumber)}
                  className={currentPage === pageNumber ? "active" : ""}
                  style={{
                    position: "relative",
                    top: "125vh",
                    fontFamily: "Pretendard Variable",
                    fontSize: "2vw",
                    textAlign: "center",
                    marginLeft: "5vw", // Add margin to create space between numbers
                    marginRight: "5vw", // Add margin to create space between numbers
                  }}
                >
                  {pageNumber}
                </span>
              ))}
              <button
                style={desktopStyle.rightArrowButtonStyle}
                onClick={goToNextPage}
              >
                &gt;
              </button>
            </div>
          </div>
        </BrowserView>
      ) : (
        <BrowserView>
          <BackButton />
          <div>
            <p style={desktopStyle.text1}>Portfolio</p>
          </div>
          <div>
            <p style={desktopStyle.text2}>Work Gallery</p>
          </div>
          <div>
            {/* <ImageBox imageUrl="/app/src/assets/workgallery/image1.png" /> */}
            {imageBoxes}
          </div>
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button
                style={desktopStyle.leftArrowButtonStyle}
                onClick={goToPrevPage}
              >
                &lt;
              </button>
              {pageNumbers.map((pageNumber) => (
                <span
                  key={pageNumber}
                  onClick={() => setCurrentPage(pageNumber)}
                  className={currentPage === pageNumber ? "active" : ""}
                  style={{
                    position: "relative",
                    top: "125vh",
                    fontFamily: "Pretendard Variable",
                    fontSize: "2vw",
                    textAlign: "center",
                    marginLeft: "5vw", // Add margin to create space between numbers
                    marginRight: "5vw", // Add margin to create space between numbers
                  }}
                >
                  {pageNumber}
                </span>
              ))}
              <button
                style={desktopStyle.rightArrowButtonStyle}
                onClick={goToNextPage}
              >
                &gt;
              </button>
            </div>
          </div>
        </BrowserView>
      )}
    </section>
  );
};

export default WorkGallery;
