// Section.js
import React, { useState } from "react";
import "../static/fonts/font.css";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import MenuIcon from "./MenuIcon";

const Header = ({ id, toggleSidebar, activeSection, setActiveSection }) => {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const isTablet = useMediaQuery({ minWidth: 481, maxWidth: 1024 });
  const isBrowser = useMediaQuery({ minWidth: 1025 });

  const [isModalOpen, setIsModalOpen] = useState(false); // Step 1: Create a state variable for the modal
  console.log("isModalOpen:", isModalOpen); // Add this for debugging
  const sectionBackgroundColors = {
    section1: "#111111",
    section2: "#111111",
    section3: "#F2F3F5",
    section4: "#2A51EC",
    section5: "#FFFFFF",
    section6: "#F2F3F5",
  };

  const sectionTextColors = {
    section1: "#111111",
    section2: "#111111",
    section3: "#F2F3F5",
    section4: "#2A51EC",
    section5: "#F2F3F5",
    section6: "#F2F3F5",
  };

  const sectionButtonColors = {
    section1: "#F2F3F5",
    section2: "#F2F3F5",
    section3: "#2A51EC",
    section4: "#F2F3F5",
    section5: "#2A51EC",
    section6: "#2A51EC",
  };

  const sectionLogoColors = {
    section1: "#F2F3F5",
    section2: "#F2F3F5",
    section3: "#111111",
    section4: "#F2F3F5",
    section5: "#111111",
    section6: "#111111",
  };

  const sectionStyle = {
    width: "100.0000vw",
    height: "8.3333vh",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    position: "fixed",
    opacity: "1.00",
    backgroundColor: isModalOpen
      ? "rgba(0, 0, 0, 0.9)"
      : sectionBackgroundColors[activeSection],
    zIndex: 1,
    transition: "background-color 0.3s ease-in-out",
  };

  const buttonStyle = {
    position: isMobile ? "relative" : isTablet ? "relative" : "relative",
    width: isMobile ? "15vw" : isTablet ? "8vw" : "6.2500vw",
    height: isMobile ? "7.5vw" : isTablet ? "4vw" : "2.0833vw",
    left: isMobile ? "-2vw" : isTablet ? "-2vw" : "-2vw",
    top: isMobile ? "0vh" : isTablet ? "0vh" : "0vh",
    opacity: "1.00",
    backgroundColor: sectionButtonColors[activeSection],
    borderRadius: isMobile ? "4vw" : isTablet ? "1.3021vw" : "1.3021vw",
    color: sectionTextColors[activeSection],
    fontFamily: "Pretendard Variable",
    fontSize: isMobile ? "2.5vw" : isTablet ? "1.5vw" : "0.8333vw",
    fontWeight: isMobile ? "700" : isTablet ? "700" : "500",
    lineHeight: "1.4583vw",
    fontStyle: "normal",
    border: "none",
    alignSelf: "center", // Vertically center align the button
    // verticalAlign: "baseline"
  };

  const logoStyle = {
    position: "relative",
    width: isMobile ? "30.5313vw" : isTablet ? "" : "10.5313vw",
    height: isMobile ? "1.3021vw" : isTablet ? "1.3021vw" : "1.3021vw",
    left: isMobile ? "5.3vw" : isTablet ? "-31vw" : "-33vw",
    top: isMobile ? "-0.7vh" : isTablet ? "-0.8vh" : "0vh",
    opacity: "1.00",
    fontFamily: "Pretendard Variable",
    borderWidth: "0.0521vw",
    fontSize: isMobile ? "4.1667vw" : isTablet ? "3vw" : "1.0417vw",
    fontWeight: isMobile ? "500" : isTablet ? "700" : "700",
    textAlign: isMobile ? "center" : "left",
    color: sectionLogoColors[activeSection],
    textDecoration: "none",
  };

  const handleClick = () => {
    const targetSection = document.getElementById("section6");

    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleLogoClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    setTimeout(() => {
      // Navigate to the home page ("/")
      window.location.href = "/";
    }, 500); // Adjust the delay time as needed
  };

  return (
    <section id={id} style={sectionStyle}>
      <>
        <MenuIcon toggleSidebar={toggleSidebar} activeSection={activeSection} />
        <Link to="/" style={{ ...logoStyle, textDecoration: "none" }}>
          <div onClick={handleLogoClick} style={{ cursor: "pointer" }}>
            <span style={{ logoStyle }}>RUI ENM</span>
          </div>
        </Link>
        <button style={buttonStyle} onClick={handleClick}>
          견적문의
        </button>
      </>
    </section>
  );
};

export default Header;
