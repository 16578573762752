import Video1 from "../assets/rui_gif.gif";

import poster0 from "../assets/workgallery/macaroniEmpitsu.jpeg";
import poster1 from "../assets/workgallery/lucklife.jpg";
import poster2 from "../assets/workgallery/novelbright.jpg";
import poster3 from "../assets/workgallery/burnoutsyndromes.jpg";
import poster4 from "../assets/workgallery/Anthem.jpg";
import poster5 from "../assets/workgallery/auyaka.jpg";
import poster6 from "../assets/workgallery/regretgirl.jpg";
import poster7 from "../assets/workgallery/lucklife_march.jpg";
import poster8 from "../assets/workgallery/wanuka.jpeg";
import subImage1 from "../assets/workgallery/lucklife_march_sub.jpg";
import subImage2 from "../assets/workgallery/auyaka_sub.jpg";
import subImage3 from "../assets/workgallery/anthem_sub.jpg";
import subImage4 from "../assets/workgallery/burnoutsyndrome_sub.jpg";
import subImage5 from "../assets/workgallery/lucklife1.jpeg";
import novelbright1 from "../assets/workgallery/novelbright/novelbright1.jpg";
import novelbright2 from "../assets/workgallery/novelbright/novelbright2.jpg";
import novelbright3 from "../assets/workgallery/novelbright/novelbright3.jpg";
import novelbright4 from "../assets/workgallery/novelbright/novelbright4.jpg";
import novelbright5 from "../assets/workgallery/novelbright/novelbright5.jpg";
import novelbright6 from "../assets/workgallery/novelbright/novelbright6.jpg";
import novelbright7 from "../assets/workgallery/novelbright/novelbright7.jpg";
import novelbright8 from "../assets/workgallery/novelbright/novelbright8.jpg";
import novelbright9 from "../assets/workgallery/novelbright/novelbright9.jpg";
import macaroni1 from "../assets/workgallery/macaroni/macaroni1.jpeg";
import macaroni2 from "../assets/workgallery/macaroni/macaroni2.jpeg";
import macaroni3 from "../assets/workgallery/macaroni/macaroni3.jpeg";
import macaroni4 from "../assets/workgallery/macaroni/macaroni4.jpeg";
import macaroni5 from "../assets/workgallery/macaroni/macaroni5.jpeg";
import macaroni6 from "../assets/workgallery/macaroni/macaroni6.jpeg";
import macaroni7 from "../assets/workgallery/macaroni/macaroni7.jpeg";
import macaroni8 from "../assets/workgallery/macaroni/macaroni8.jpeg";
import macaroni9 from "../assets/workgallery/macaroni/macaroni9.jpeg";
import auyakaPoster from "../assets/workgallery/auyaka_april.jpeg";
import anthemPoster from "../assets/workgallery/anthem_june.jpg";
import auyaka1 from "../assets/workgallery/auyaka_april_2.jpeg";
import auyakaJunePoster from "../assets/workgallery/auyaka_june_poster.jpeg";
import choTokimekiSendenbu from "../assets/workgallery/choTokimekiSendenbuPoster.jpeg";
import xinU from "../assets/workgallery/xinu.jpeg";
import novelbright_oct from "../assets/workgallery/novelOct.jpg"
import ascaPoster from "../assets/workgallery/Asca.jpeg"
import auyakaDetail from "../assets/workgallery/auyakaDetails.jpeg"
import centimillimentalPoster from "../assets/workgallery/centimillimental.jpeg"
import haruna_poster from "../assets/workgallery/haruna_poster.jpeg"
import wacci_poster from "../assets/workgallery/wacci_poster.jpeg"
import theshesgone_poster from "../assets/workgallery/theshesgone_poster.jpeg"

const ImageUrls = [
  theshesgone_poster,
  wacci_poster,
  centimillimentalPoster,
  haruna_poster,
  novelbright_oct,
  ascaPoster,
  xinU,
  choTokimekiSendenbu,
  auyakaJunePoster,
  anthemPoster,
  auyakaPoster,
  poster8,
  poster0,
  poster1,
  poster2,
  poster3,
  poster4,
  poster5,
  poster6,
  poster7,
  Video1,
  subImage1,
  subImage2,
  subImage3,
  subImage4,
  subImage5,
  novelbright1,
  novelbright2,
  novelbright3,
  novelbright4,
  novelbright5,
  novelbright6,
  novelbright7,
  novelbright8,
  novelbright9,
  macaroni1,
  macaroni2,
  macaroni3,
  macaroni4,
  macaroni5,
  macaroni6,
  macaroni7,
  macaroni8,
  macaroni9,
  auyaka1,
  auyakaDetail
];

export default ImageUrls;
