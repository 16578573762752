// Section.js
import React from "react";
import "../static/fonts/font.css";
import { useMediaQuery } from "react-responsive";
import arrowLine from "../assets/about_line.png";

const About = ({ id }) => {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const isTablet = useMediaQuery({ minWidth: 481, maxWidth: 1024 });
  const isBrowser = useMediaQuery({ minWidth: 1024 });

  const sectionStyle = {
    width: "100.0000vw",
    height: isTablet ? "66vh" : "100.0000vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#111111",
  };

  const text1 = {
    position: "absolute",
    width: isMobile ? "" : isTablet ? "10vw" : "2.2396vw",
    height: isMobile ? "1.8519vh" : isTablet ? "1.8519vh" : "1.8519vh",
    left: isMobile ? "42vw" : isTablet ? "9.3750vw" : "9.3750vw",
    top: isMobile ? "111vh" : isTablet ? "85vh" : "123.1481vh",
    opacity: "1.00",
    fontFamily: "Pretendard Variable",
    fontSize: isMobile ? "5.1250vw" : isTablet ? "1.5vw" : "1.0471vw",
    fontWeight: "500",
    fontStyle: "normal",
    textAlign: isMobile ? "center" : "left",
    lineHeight: "4.8438vw",
    color: "#F2F3F5",
  };

  const text2 = {
    position: "absolute",
    width: isMobile ? "" : isTablet ? "40vw" : "20.4479vw",
    height: isMobile ? "6.3889vh" : isTablet ? "6.3889vh" : "6.3889vh",
    left: isMobile ? "20vw" : isTablet ? "9.3750vw" : "9.3750vw",
    top: isMobile ? "116vh" : isTablet ? "87vh" : "125.9259vh",
    opacity: "1.00",
    fontFamily: "SCoreDream",
    fontSize: isMobile ? "9.3750vw" : isTablet ? "4vw" : "3.1250vw",
    fontWeight: "900",
    fontStyle: "normal",
    textAlign: isMobile ? "center" : "left",
    lineHeight: "4.6875vw",
    color: "#F2F3F5",
  };

  const text3 = {
    position: "absolute",
    width: isMobile ? "30vw" : isTablet ? "16.7188vw" : "16.7188vw",
    height: isMobile ? "24.7222vh" : isTablet ? "24.7222vh" : "24.7222vh",
    left: isMobile ? "16.6667vw" : isTablet ? "14.0625vw" : "14.0625vw",
    top: isMobile ? "140vh" : isTablet ? "102vh" : "150.7407vh",
    opacity: "1.00",
    fontFamily: "Pretendard Variable",
    fontSize: isMobile ? "4.1667vw" : isTablet ? "2vw" : "1.2500vw",
    fontWeight: isMobile ? "700" : "500",
    textAlign: "center",
    color: "#F2F3F5",
  };

  const text3small = {
    position: "absolute",
    width: isMobile ? "36vw" : isTablet ? "16.7188vw" : "16.7188vw",
    height: isMobile ? "" : isTablet ? "24.7222vh" : "24.7222vh",
    left: isMobile ? "14vw" : isTablet ? "14.0625vw" : "14.0625vw",
    top: isMobile ? "151vh" : isTablet ? "110vh" : "165vh",
    opacity: "1.00",
    fontFamily: "Pretendard Variable",
    fontSize: isMobile ? "2vw" : isTablet ? "1.1667vw" : "0.6250vw",
    fontWeight: isMobile ? "700" : "500",
    textAlign: "center",
    lineHeight: isMobile ? "1.8vh" : "2vh",
    color: "#F2F3F5",
  };

  const text4 = {
    position: "absolute",
    width: isMobile ? "30vw" : isTablet ? "16.7188vw" : "16.7188vw",
    height: isMobile ? "24.7222vh" : isTablet ? "24.7222vh" : "24.7222vh",
    left: isMobile ? "53vw" : isTablet ? "32.3438vw" : "32.3438vw",
    top: isMobile ? "140vh" : isTablet ? "102vh" : "150.7407vh",
    opacity: "1.00",
    fontFamily: "Pretendard Variable",
    fontSize: isMobile ? "4.1667vw" : isTablet ? "2vw" : "1.2500vw",
    fontWeight: isMobile ? "700" : "500",
    textAlign: "center",
    color: "#F2F3F5",
  };

  const text4small = {
    position: "absolute",
    width: isMobile ? "36vw" : isTablet ? "16.7188vw" : "16.7188vw",
    height: isMobile ? "" : isTablet ? "24.7222vh" : "24.7222vh",
    left: isMobile ? "50vw" : isTablet ? "32.3438vw" : "32.3438vw",
    top: isMobile ? "151vh" : isTablet ? "110vh" : "165vh",
    opacity: "1.00",
    fontFamily: "Pretendard Variable",
    fontSize: isMobile ? "2vw" : isTablet ? "1.1667vw" : "0.6250vw",
    fontWeight: isMobile ? "700" : "500",
    textAlign: "center",
    lineHeight: isMobile ? "1.8vh" : "2vh",
    color: "#F2F3F5",
  };

  const text5 = {
    position: "absolute",
    width: isMobile ? "30vw" : isTablet ? "16.7188vw" : "16.7188vw",
    height: isMobile ? "24.7222vh" : isTablet ? "24.7222vh" : "24.7222vh",
    left: isMobile ? "16.6667vw" : isTablet ? "50.6250vw" : "50.6250vw",
    top: isMobile ? "165vh" : isTablet ? "102vh" : "150.7407vh",
    opacity: "1.00",
    fontFamily: "Pretendard Variable",
    fontSize: isMobile ? "4.1667vw" : isTablet ? "2vw" : "1.2500vw",
    fontWeight: isMobile ? "700" : "500",
    textAlign: "center",
    color: "#F2F3F5",
  };

  const text5small = {
    position: "absolute",
    width: isMobile ? "36vw" : isTablet ? "16.7188vw" : "16.7188vw",
    height: isMobile ? "" : isTablet ? "24.7222vh" : "24.7222vh",
    left: isMobile ? "14vw" : isTablet ? "50.6250vw" : "50.6250vw",
    top: isMobile ? "176vh" : isTablet ? "110vh" : "165vh",
    opacity: "1.00",
    fontFamily: "Pretendard Variable",
    fontSize: isMobile ? "2vw" : isTablet ? "1.1667vw" : "0.6250vw",
    fontWeight: isMobile ? "700" : "500",
    textAlign: "center",
    lineHeight: isMobile ? "1.8vh" : "2vh",
    color: "#F2F3F5",
  };

  const text6 = {
    position: "absolute",
    width: isMobile ? "30vw" : isTablet ? "16.7188vw" : "16.7188vw",
    height: isMobile ? "24.7222vh" : isTablet ? "24.7222vh" : "24.7222vh",
    left: isMobile ? "53vw" : isTablet ? "68.9063vw" : "68.9063vw",
    top: isMobile ? "165vh" : isTablet ? "102vh" : "150.7407vh",
    opacity: "1.00",
    fontFamily: "Pretendard Variable",
    fontSize: isMobile ? "4.1667vw" : isTablet ? "2vw" : "1.2500vw",
    fontWeight: isMobile ? "700" : "500",
    textAlign: "center",
    color: "#F2F3F5",
  };

  const text6small = {
    position: "absolute",
    width: isMobile ? "36vw" : isTablet ? "16.7188vw" : "16.7188vw",
    height: isMobile ? "" : isTablet ? "24.7222vh" : "24.7222vh",
    left: isMobile ? "50vw" : isTablet ? "68.9063vw" : "68.9063vw",
    top: isMobile ? "176vh" : isTablet ? "110vh" : "165vh",
    opacity: "1.00",
    fontFamily: "Pretendard Variable",
    fontSize: isMobile ? "2vw" : isTablet ? "1.1667vw" : "0.6250vw",
    fontWeight: isMobile ? "700" : "500",
    textAlign: "center",
    lineHeight: isMobile ? "1.8vh" : "2vh",
    color: "#F2F3F5",
  };

  const pageGroup = {
    position: "absolute",
    width: isMobile ? "18vw" : "5.7292vw",
    height: isMobile ? "18vw" : "5.7292vw",
    left: isMobile ? "80vw" : "92.1875vw",
    top: isMobile ? "190vh" : isTablet ? "126vh" : "185vh",
    backgroundColor: "transparent",
  };

  const page1 = {
    position: "absolute",
    width: isMobile ? "3.5417vw" : "3.5417vw",
    height: isMobile ? "3.5938vw" : "6.2963vh",
    top: isMobile ? "-3.5vh" : "-4.1667vw",
    opacity: "1.00",
    fontFamily: "Pretendard Variable",
    fontSize: isMobile ? "9.3750vw" : "3.1250vw",
    fontWeight: isMobile ? "900" : "500",
    fontStyle: "normal",
    textAlign: "center",
    lineHeight: "4.8438vw",
    color: "#F2F3F5",
  };

  const page2 = {
    position: "absolute",
    width: isMobile ? "2.3958vw" : "2.3958vw",
    height: isMobile ? "2.4479vw" : "6.2963vh",
    left: isMobile ? "9vw" : "3vw",
    top: isMobile ? "3vh" : "0vh",
    opacity: "0.20",
    fontFamily: "Pretendard Variable",
    fontSize: isMobile ? "6.2500vw" : "2.0833vw",
    fontWeight: isMobile ? "900" : "500",
    fontStyle: "normal",
    textAlign: "center",
    lineHeight: "4.8438vw",
    color: "#F2F3F5",
  };

  const divider = {
    position: "absolute",
    width: isMobile ? "10vw" : "2.6042vw",
    height: isMobile ? "0.1042vw" : "0.1042vw",
    left: isMobile ? "5vw" : "2vw",
    top: isMobile ? "11vw" : "3.27vw",
    opacity: "1.00",
    backgroundColor: "#F2F3F5",
    transform: "rotate(-45deg)",
  };

  const circle1 = {
    position: "absolute",
    width: isMobile ? "2.5208vw" : isTablet ? "0.7813vw" : "0.5208vw",
    height: isMobile ? "2.5208vw" : isTablet ? "0.7813vw" : "0.5208vw",
    left: isMobile ? "30.5vw" : isTablet ? "22.1875vw" : "22.1875vw",
    top: isMobile ? "139.5vh" : isTablet ? "100vh" : "150.7407vh",
    opacity: "1.00",
    backgroundColor: "#F2F3F5",
    borderRadius: "50%",
  };

  const circle2 = {
    position: "absolute",
    width: isMobile ? "2.5208vw" : isTablet ? "0.7813vw" : "0.5208vw",
    height: isMobile ? "2.5208vw" : isTablet ? "0.7813vw" : "0.5208vw",
    left: isMobile ? "67vw" : isTablet ? "40.4688vw" : "40.4688vw",
    top: isMobile ? "139.5vh" : isTablet ? "100vh" : "150.7407vh",
    opacity: "1.00",
    backgroundColor: "#F2F3F5",
    borderRadius: "50%",
  };

  const circle3 = {
    position: "absolute",
    width: isMobile ? "2.5208vw" : isTablet ? "0.7813vw" : "0.5208vw",
    height: isMobile ? "2.5208vw" : isTablet ? "0.7813vw" : "0.5208vw",
    left: isMobile ? "30.5vw" : isTablet ? "58.7500vw" : "58.7500vw",
    top: isMobile ? "164.5vh" : isTablet ? "100vh" : "150.7407vh",
    opacity: "1.00",
    backgroundColor: "#F2F3F5",
    borderRadius: "50%",
  };

  const circle4 = {
    position: "absolute",
    width: isMobile ? "2.5208vw" : isTablet ? "0.7813vw" : "0.5208vw",
    height: isMobile ? "2.5208vw" : isTablet ? "0.7813vw" : "0.5208vw",
    left: isMobile ? "67vw" : isTablet ? "77.0313vw" : "77.0313vw",
    top: isMobile ? "164.5vh" : isTablet ? "100vh" : "150.7407vh",
    opacity: "1.00",
    backgroundColor: "#F2F3F5",
    borderRadius: "50%",
  };

  const line = {
    position: "absolute",
    width: "85.6250vw",
    height: "0.0926vh",
    left: "0.0521vw",
    top: isMobile ? "140vh" : "151.2037vh",
    opacity: "0.2",
    backgroundColor: "#F2F3F5",
    borderColor: "#F2F3F5",
  };

  const line2 = {
    position: isMobile ? "absolute" : "",
    width: isMobile ? "85.6250vw" : "",
    height: isMobile ? "0.0926vh" : "",
    left: isMobile ? "0.0521vw" : "",
    top: isMobile ? "165.0000vh" : "",
    opacity: isMobile ? "0.2" : "",
    backgroundColor: isMobile ? "#F2F3F5" : "",
    borderColor: isMobile ? "#F2F3F5" : "",
  };

  return (
    <section id={id} style={sectionStyle}>
      <div>
        <p style={text1}>About</p>
      </div>
      <div>
        <p style={text2}>What we do</p>
      </div>
      {isMobile ? (
        <div>
          <div style={line}></div>
          <div style={line2}></div>
          <div style={circle1}></div>
          <div style={circle2}></div>
          <div style={circle3}></div>
          <div style={circle4}></div>
        </div>
      ) : (
        <div>
          <img
            src={arrowLine}
            style={{
              position: "relative",
              left: "-7vw",
              top: "1.4vh",
              width: "85vw",
            }}
          />
          <div style={circle1}></div>
          <div style={circle2}></div>
          <div style={circle3}></div>
          <div style={circle4}></div>
        </div>
      )}

      <div>
        <p style={text3}>
          01 <br />
          <br /> Entertainment
        </p>
        <p style={text3small}>
          공연, 행사, 전시 등의 <br /> 대규모 공연 기획 및 아티스트 육성
        </p>
      </div>
      <div>
        <p style={text4}>
          02 <br />
          <br /> Metaverse
        </p>
        <p style={text4small}>
          Ai 연예인 육성과 NFT를 활용한 <br /> 저작권 공유 플랫폼 운영
        </p>
      </div>
      <div>
        <p style={text5}>
          03 <br />
          <br /> Contents
        </p>
        <p style={text5small}>
          영화 · 드라마 · 영상 <br /> 콘텐츠의 개발 및 유통 사업
        </p>
      </div>
      <div>
        <p style={text6}>
          04 <br />
          <br /> Marketing
        </p>
        <p style={text6small}>
          온라인 OTT 서비스 및 <br /> 오프라인 공연 마케팅
        </p>
      </div>
      <div style={pageGroup}>
        <div>
          <p style={page1}>02</p>
        </div>
        <div>
          <p style={page2}>06</p>
        </div>
        <div style={divider}></div>
      </div>
    </section>
  );
};

export default About;
