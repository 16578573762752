import React, { useState, useEffect } from "react";
import MainPage from "./MainPage";
import About from "./About";
import Portfolio from "./Portfolio";
import Process from "./Process";
import Store from "./Store";
import Contact from "./Contact";
import $ from "jquery"; // Import jQuery
import { Link, animateScroll as scroll } from "react-scroll";

const FullpageLayout = () => {
  const [activeSection, setActiveSection] = useState("section1"); // Initialize with the default section

  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      const yOffset =
        sectionElement.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({ top: yOffset, behavior: "smooth" });
    }
  };

  //full page scrolling

  // useEffect(() => {
  //   var $html = $("html");
  //   var page = 1;
  //   var lastPage = $("section").length;

  //   // Scroll animation time
  //   var timeScrollAnimation = 750;

  //   $html.animate({ scrollTop: 0 }, 10);

  //   $(window).on("wheel", function (e) {
  //     if ($html.is(":animated")) return;

  //     if (e.originalEvent.deltaY > 0) {
  //       if (page == lastPage) return;

  //       page++;
  //     } else if (e.originalEvent.deltaY < 0) {
  //       if (page == 1) return;

  //       page--;
  //     }

  //     var posTop = (page - 1) * $(window).height();

  //     $html.animate({ scrollTop: posTop }, timeScrollAnimation);
  //   });
  // }, []);

  return (
    <div>
      <MainPage
        id="section1"
        setActiveSection={() => {
          setActiveSection("section1");
          scrollToSection("section2");
        }}
      />
      <About
        id="section2"
        setActiveSection={() => {
          setActiveSection("section2");
          scrollToSection("section3");
        }}
      />
      <Portfolio
        id="section3"
        setActiveSection={() => {
          setActiveSection("section3");
          scrollToSection("section4");
        }}
      />
      <Process
        id="section4"
        setActiveSection={() => {
          setActiveSection("section4");
          scrollToSection("section5");
        }}
      />
      <Store
        id="section5"
        setActiveSection={() => {
          setActiveSection("section5");
          scrollToSection("section6");
        }}
      />
      <Contact
        id="section6"
        setActiveSection={() => {
          setActiveSection("section6");
          scrollToSection("section1");
        }}
      />
    </div>
  );
};

export default FullpageLayout;
