// Section.js
import React from "react";
import "../static/fonts/font.css";
import { useMediaQuery } from "react-responsive";
import process1 from "../assets/process_1.png";
import process2 from "../assets/process_2.png";
import process3 from "../assets/process_3.png";
import process4 from "../assets/process_4.png";
import process5 from "../assets/process_5.png";
import process6 from "../assets/process_6.png";
import gradientImg from "../assets/Gradient.png";
import boxLeft from "../assets/bubble_left.png";
import boxRight from "../assets/bubble_right.png";

const Process = ({ id }) => {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const isTablet = useMediaQuery({ minWidth: 481, maxWidth: 1024 });
  const isBrowser = useMediaQuery({ minWidth: 1024 });

  const sectionStyle = {
    width: "100.0000vw",
    height: isTablet ? "66vh" : "100.0000vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#2A51EC",
    overflowY: "hidden",
  };

  const textStyle = {
    position: "absolute",
    width: isMobile ? "" : isTablet ? "3.0729vw" : "3.0729vw",
    height: isMobile ? "1.8519vh" : isTablet ? "1.8519vh" : "1.8519vh",
    left: isMobile ? "44vw" : isTablet ? "9.3750vw" : "9.3750vw",
    top: isMobile ? "311vh" : isTablet ? "224vh" : "332.4074vh",
    opacity: "0.50",
    fontFamily: "Pretendard Variable",
    fontSize: isMobile ? "3.1250vw" : isTablet ? "1.5vw" : "1.0471vw",
    fontWeight: "500",
    textAlign: isMobile ? "center" : "left",
    lineHeight: "4.8438vw",
    color: "#F2F3F5",
  };

  const text1 = {
    position: "absolute",
    width: isMobile ? "" : isTablet ? "" : "",
    height: isMobile ? "1.8519vh" : isTablet ? "1.8519vh" : "1.8519vh",
    left: isMobile ? "32vw" : isTablet ? "9.3750vw" : "9.3750vw",
    top: isMobile ? "314vh" : isTablet ? "226vh" : "334.4074vh",
    opacity: "1.00",
    fontFamily: "SCoreDream",
    fontSize: isMobile ? "9.3750vw" : isTablet ? "4vw" : "3.1250vw",
    fontWeight: "900",
    textAlign: isMobile ? "center" : "left",
    lineHeight: "4.6875vw",
    color: "#F2F3F5",
  };

  const text2 = {
    position: "absolute",
    width: isMobile ? "" : isTablet ? "" : "20.7083vw",
    height: isMobile ? "5.9259vh" : isTablet ? "5.9259vh" : "5.9259vh",
    left: isMobile ? "27vw" : isTablet ? "9.3750vw" : "9.3750vw",
    top: isMobile ? "323vh" : isTablet ? "234vh" : "350.7963vh",
    opacity: "1.00",
    fontFamily: "Pretendard Variable",
    fontSize: isMobile ? "3.1250vw" : isTablet ? "1.8vw" : "1.2500vw",
    fontWeight: "500",
    textAlign: isMobile ? "center" : "left",
    lineHeight: isMobile ? "4.6875vw" : isTablet ? "2.5vw" : "1.8229vw",
    color: "#F2F3F5",
  };

  const text3 = {
    position: "absolute",
    width: isMobile ? "50vw" : isTablet ? "" : "15.6250vw",
    height: isMobile ? "13.8889vh" : isTablet ? "13.8889vh" : "13.8889vh",
    left: isMobile ? "5vw" : "3.0104vw",
    top: isMobile ? "8vh" : isTablet ? "10vh" : "10vh",
    opacity: "1.00",
    color: "#2A51EC",
    fontFamily: "Pretendard Variable",
    fontSize: isMobile ? "5.2083vw" : isTablet ? "3vw" : "1.5625vw",
    fontWeight: "500",
    textAlign: "left",
    lineHeight: isMobile ? "" : isTablet ? "3vw" : "1.5000vw",
    marginLeft: isMobile ? "3vw" : isTablet ? "2vw" : "",
  };

  const text4 = {
    position: "absolute",
    width: isMobile ? "70vw" : isTablet ? "" : "15.6250vw",
    height: isMobile ? "13.8889vh" : isTablet ? "13.8889vh" : "13.8889vh",
    right: isMobile ? "6vw" : "12vw",
    top: isMobile ? "30vh" : isTablet ? "28vh" : "34vh",
    opacity: "1.00",
    color: "#2A51EC",
    fontFamily: "Pretendard Variable",
    fontSize: isMobile ? "5.2083vw" : isTablet ? "3vw" : "1.5625vw",
    fontWeight: "500",
    textAlign: "right",
    lineHeight: isMobile ? "" : isTablet ? "3vw" : "1.5000vw",
    marginRight: isMobile ? "3vw" : isTablet ? "2vw" : "",
  };

  const text5 = {
    position: "absolute",
    width: isMobile ? "60vw" : isTablet ? "" : "15.6250vw",
    height: isMobile ? "13.8889vh" : isTablet ? "13.8889vh" : "13.8889vh",
    left: isMobile ? "5vw" : "3.0104vw",
    top: isMobile ? "52vh" : isTablet ? "46vh" : "58vh",
    opacity: "1.00",
    color: "#2A51EC",
    fontFamily: "Pretendard Variable",
    fontSize: isMobile ? "5.2083vw" : isTablet ? "3vw" : "1.5625vw",
    fontWeight: "500",
    textAlign: "left",
    lineHeight: isMobile ? "" : isTablet ? "3vw" : "1.5000vw",
    marginLeft: isMobile ? "3vw" : isTablet ? "2vw" : "",
  };

  const text6 = {
    position: "absolute",
    width: isMobile ? "60vw" : isTablet ? "" : "15.6250vw",
    height: isMobile ? "13.8889vh" : isTablet ? "13.8889vh" : "13.8889vh",
    right: isMobile ? "6vw" : "12vw",
    top: isMobile ? "74vh" : isTablet ? "64vh" : "82vh",
    opacity: "1.00",
    color: "#2A51EC",
    fontFamily: "Pretendard Variable",
    fontSize: isMobile ? "5.2083vw" : isTablet ? "3vw" : "1.5625vw",
    fontWeight: "500",
    textAlign: "right",
    lineHeight: isMobile ? "" : isTablet ? "3vw" : "1.5000vw",
    marginRight: isMobile ? "3vw" : isTablet ? "2vw" : "",
  };

  const text7 = {
    position: "absolute",
    width: isMobile ? "60vw" : isTablet ? "" : "15.6250vw",
    height: isMobile ? "13.8889vh" : isTablet ? "13.8889vh" : "13.8889vh",
    left: isMobile ? "5vw" : "3.0104vw",
    top: isMobile ? "96vh" : isTablet ? "82vh" : "106vh",
    opacity: "1.00",
    color: "#2A51EC",
    fontFamily: "Pretendard Variable",
    fontSize: isMobile ? "5.2083vw" : isTablet ? "3vw" : "1.5625vw",
    fontWeight: "500",
    textAlign: "left",
    lineHeight: isMobile ? "" : isTablet ? "3vw" : "1.5000vw",
    marginLeft: isMobile ? "3vw" : isTablet ? "2vw" : "",
  };

  const text8 = {
    position: "absolute",
    width: isMobile ? "60vw" : isTablet ? "" : "15.6250vw",
    height: isMobile ? "13.8889vh" : isTablet ? "13.8889vh" : "13.8889vh",
    right: isMobile ? "6vw" : "12vw",
    top: isMobile ? "118vh" : isTablet ? "100vh" : "130vh",
    opacity: "1.00",
    color: "#2A51EC",
    fontFamily: "Pretendard Variable",
    fontSize: isMobile ? "5.2083vw" : isTablet ? "3vw" : "1.5625vw",
    fontWeight: "500",
    textAlign: "right",
    lineHeight: isMobile ? "" : isTablet ? "3vw" : "1.5000vw",
    marginRight: isMobile ? "3vw" : isTablet ? "2vw" : "",
  };

  const box1 = {
    position: "absolute",
    width: isMobile ? "85vw" : isTablet ? "48vw" : "31.2500vw",
    height: isMobile ? "18.5185vh" : isTablet ? "20vw" : "10.4167vw",
    top: isMobile ? "8vh" : isTablet ? "10vh" : "10vh",
    opacity: "1.00",
  };

  const box2 = {
    position: "absolute",
    width: isMobile ? "85vw" : isTablet ? "48vw" : "31.2500vw",
    height: isMobile ? "18.5185vh" : isTablet ? "20vw" : "10.4167vw",
    right: isMobile ? "2vw" : "9.3750vw",
    top: isMobile ? "30vh" : isTablet ? "28vh" : "34vh",
    opacity: "1.00",
  };

  const box3 = {
    position: "absolute",
    width: isMobile ? "85vw" : isTablet ? "48vw" : "31.2500vw",
    height: isMobile ? "18.5185vh" : isTablet ? "20vw" : "10.4167vw",
    top: isMobile ? "52vh" : isTablet ? "46vh" : "58vh",
    opacity: "1.00",
  };

  const box4 = {
    position: "absolute",
    width: isMobile ? "85vw" : isTablet ? "48vw" : "31.2500vw",
    height: isMobile ? "18.5185vh" : isTablet ? "20vw" : "10.4167vw",
    right: isMobile ? "2vw" : "9.3750vw",
    top: isMobile ? "74vh" : isTablet ? "64vh" : "82vh",
    opacity: "1.00",
  };

  const box5 = {
    position: "absolute",
    width: isMobile ? "85vw" : isTablet ? "48vw" : "31.2500vw",
    height: isMobile ? "18.5185vh" : isTablet ? "20vw" : "10.4167vw",
    top: isMobile ? "96vh" : isTablet ? "82vh" : "106vh",
    opacity: "1.00",
  };

  const box6 = {
    position: "absolute",
    width: isMobile ? "85vw" : isTablet ? "48vw" : "31.2500vw",
    height: isMobile ? "18.5185vh" : isTablet ? "20vw" : "10.4167vw",
    right: isMobile ? "2vw" : "9.3750vw",
    top: isMobile ? "118vh" : isTablet ? "100vh" : "130vh",
    opacity: "1.00",
  };

  const box7 = {
    position: "absolute",
    width: isMobile ? "90vw" : isTablet ? "48vw" : "29.8438vw",
    height: isMobile ? "5vh" : isTablet ? "20vw" : "18.5185vh",
    top: isMobile ? "140vh" : isTablet ? "118vh" : "154vh",
    opacity: "1.00",
    background: "transparent",
  };

  const image1 = {
    position: "absolute",
    height: isMobile ? "30vw" : isTablet ? "12vw" : "8vw",
    left: isMobile ? "55vw" : isTablet ? "30vw" : "22vw",
    top: isMobile ? "10vh" : isTablet ? "13vh" : "12vh",
    opacity: "1.00",
  };

  const image2 = {
    position: "absolute",
    height: isMobile ? "25vw" : isTablet ? "12vw" : "8vw",
    right: isMobile ? "55vw" : isTablet ? "36vw" : "28vw",
    top: isMobile ? "32vh" : isTablet ? "31vh" : "36vh",
    opacity: "1.00",
  };

  const image3 = {
    position: "absolute",
    height: isMobile ? "30vw" : isTablet ? "12vw" : "8vw",
    left: isMobile ? "55vw" : isTablet ? "30vw" : "22vw",
    top: isMobile ? "54vh" : isTablet ? "49vh" : "60vh",
    opacity: "1.00",
  };

  const image4 = {
    position: "absolute",
    height: isMobile ? "30vw" : isTablet ? "12vw" : "8vw",
    right: isMobile ? "55vw" : isTablet ? "38vw" : "30vw",
    top: isMobile ? "76vh" : isTablet ? "67vh" : "84vh",
    opacity: "1.00",
  };

  const image5 = {
    position: "absolute",
    height: isMobile ? "30vw" : isTablet ? "12vw" : "8vw",
    left: isMobile ? "55vw" : isTablet ? "30vw" : "22vw",
    top: isMobile ? "98vh" : isTablet ? "86vh" : "108vh",
    opacity: "1.00",
  };

  const image6 = {
    position: "absolute",
    height: isMobile ? "30vw" : isTablet ? "12vw" : "8vw",
    right: isMobile ? "50vw" : isTablet ? "38vw" : "29vw",
    top: isMobile ? "120vh" : isTablet ? "104vh" : "132vh",
    opacity: "1.00",
  };

  const container = {
    position: "absolute",
    width: isMobile ? "90vw" : isTablet ? "58.9583vw" : "58.9583vw",
    height: isMobile ? "55vh" : isTablet ? "66vh" : "100%",
    left: isMobile ? "7vw" : isTablet ? "40vw" : "40vw",
    top: isMobile ? "330vh" : isTablet ? "198vh" : "300vh",
    overflowY: "scroll",
  };

  const pageGroup = {
    position: "absolute",
    width: isMobile ? "18vw" : "5.7292vw",
    height: isMobile ? "18vw" : "5.7292vw",
    left: isMobile ? "80vw" : "92.1875vw",
    top: isMobile ? "390vh" : isTablet ? "258vh" : "385vh",
    backgroundColor: "transparent",
  };

  const page1 = {
    position: "absolute",
    width: isMobile ? "3.5417vw" : "3.5417vw",
    height: isMobile ? "3.5938vw" : "6.2963vh",
    // left: "0.0000vw",
    top: isMobile ? "-3.5vh" : "-4.1667vw",
    opacity: "1.00",
    fontFamily: "Pretendard Variable",
    fontSize: isMobile ? "9.3750vw" : "3.1250vw",
    fontWeight: isMobile ? "900" : "500",
    fontStyle: "normal",
    textAlign: "center",
    lineHeight: "4.8438vw",
    color: "#F2F3F5",
  };

  const page2 = {
    position: "absolute",
    width: isMobile ? "2.3958vw" : "3.5417vw",
    height: isMobile ? "2.4479vw" : "6.2963vh",
    left: isMobile ? "9vw" : "2.4vw",
    top: isMobile ? "3vh" : "0vh",
    opacity: "0.20",
    fontFamily: "Pretendard Variable",
    fontSize: isMobile ? "6.2500vw" : "2.0833vw",
    fontWeight: isMobile ? "900" : "500",
    fontStyle: "normal",
    textAlign: "center",
    lineHeight: "4.8438vw",
    color: "#F2F3F5",
  };

  const divider = {
    position: "absolute",
    width: isMobile ? "10vw" : "2.6042vw",
    height: isMobile ? "0.1042vw" : "0.1042vw",
    left: isMobile ? "5.5vw" : "2vw",
    top: isMobile ? "11vw" : "3vw",
    opacity: "1.00",
    backgroundColor: "#F2F3F5",
    transform: "rotate(-45deg)",
  };

  return (
    <section id={id} style={sectionStyle}>
      <div>
        <p style={textStyle}>Process</p>
      </div>
      <div>
        <p style={text1}>Process</p>
      </div>
      {/* <div>
        <p style={text2}>
          투명하게 진행되는 모든 과정 <br /> 고객과의 신뢰를 저버리지 않습니다.
        </p>
      </div> */}

      <div style={container}>
        <div>
          <img src={boxLeft} alt="boxLeft" style={box1} />
          <img src={process1} alt="process1" style={image1} />
          <p style={text3}>
            01. <br />{" "}
            <span
              style={{
                fontWeight: "900",
                lineHeight: isMobile
                  ? "10.0000vw"
                  : isTablet
                  ? "5vw"
                  : "2.5000vw",
              }}
            >
              견적문의
            </span>{" "}
            <br />
            <span
              style={{
                fontSize: isMobile ? "4vw" : isTablet ? "1.8vw" : "1vw",
                lineHeight: isMobile
                  ? "1.0000vw"
                  : isTablet
                  ? "1vw"
                  : "1.0000vw",
              }}
            >
              진행하고자 하는 프로젝트에 <br /> 대한 견적을 문의합니다.
            </span>
          </p>
        </div>
        <div>
          <img src={boxRight} alt="boxRight" style={box2} />
          <img src={process2} alt="process1" style={image2} />
          <p style={text4}>
            02. <br />{" "}
            <span
              style={{
                fontWeight: "900",
                lineHeight: isMobile
                  ? "10.0000vw"
                  : isTablet
                  ? "5vw"
                  : "2.5000vw",
              }}
            >
              사전미팅
            </span>{" "}
            <br />
            <span
              style={{
                fontSize: isMobile ? "4vw" : isTablet ? "1.8vw" : "1vw",
                lineHeight: isMobile
                  ? "1.0000vw"
                  : isTablet
                  ? "1vw"
                  : "1.0000vw",
              }}
            >
              프로젝트 내용을 공유하고 자세한 <br /> 내용파악을 위한 미팅을
              진행합니다.
            </span>
          </p>
        </div>
        <div>
          <img src={boxLeft} alt="boxLeft" style={box3} />
          <img src={process3} alt="process1" style={image3} />
          <p style={text5}>
            03. <br />{" "}
            <span
              style={{
                fontWeight: "900",
                lineHeight: isMobile
                  ? "10.0000vw"
                  : isTablet
                  ? "5vw"
                  : "2.5000vw",
              }}
            >
              계약진행
            </span>{" "}
            <br />
            <span
              style={{
                fontSize: isMobile ? "4vw" : isTablet ? "1.8vw" : "1vw",
                lineHeight: isMobile
                  ? "1.0000vw"
                  : isTablet
                  ? "1vw"
                  : "1.0000vw",
              }}
            >
              계약서를 작성하고 <br /> 계약금을 입금합니다.{" "}
            </span>
          </p>
        </div>
        <div>
          <img src={boxRight} alt="boxRight" style={box4} />
          <img src={process4} alt="process1" style={image4} />
          <p style={text6}>
            04. <br />{" "}
            <span
              style={{
                fontWeight: "900",
                lineHeight: isMobile
                  ? "10.0000vw"
                  : isTablet
                  ? "5vw"
                  : "2.5000vw",
              }}
            >
              기획안 브리핑
            </span>{" "}
            <br />
            <span
              style={{
                fontSize: isMobile ? "4vw" : isTablet ? "1.8vw" : "1vw",
                lineHeight: isMobile
                  ? "1.0000vw"
                  : isTablet
                  ? "1vw"
                  : "1.0000vw",
              }}
            >
              기획된 프로젝트의 <br /> 브리핑을 듣고 피드백합니다.
            </span>
          </p>
        </div>
        <div>
          <img src={boxLeft} alt="boxLeft" style={box5} />
          <img src={process5} alt="process1" style={image5} />
          <p style={text7}>
            05. <br />{" "}
            <span
              style={{
                fontWeight: "900",
                lineHeight: isMobile
                  ? "10.0000vw"
                  : isTablet
                  ? "5vw"
                  : "2.5000vw",
              }}
            >
              최종 피드백
            </span>{" "}
            <br />
            <span
              style={{
                fontSize: isMobile ? "4vw" : isTablet ? "1.8vw" : "1vw",
                lineHeight: isMobile
                  ? "1.0000vw"
                  : isTablet
                  ? "1vw"
                  : "1.0000vw",
              }}
            >
              기획안에 대한 최종 피드백과 <br /> 보완 과정을 거칩니다.
            </span>
          </p>
        </div>
        <div>
          <img src={boxRight} alt="boxRight" style={box6} />
          <img src={process6} alt="process1" style={image6} />
          <p style={text8}>
            06. <br />{" "}
            <span
              style={{
                fontWeight: "900",
                lineHeight: isMobile
                  ? "10.0000vw"
                  : isTablet
                  ? "5vw"
                  : "2.5000vw",
              }}
            >
              프로젝트 진행
            </span>{" "}
            <br />
            <span
              style={{
                fontSize: isMobile ? "4vw" : isTablet ? "1.8vw" : "1vw",
                lineHeight: isMobile
                  ? "1.0000vw"
                  : isTablet
                  ? "1vw"
                  : "1.0000vw",
              }}
            >
              루이 E&M의 맞춤형 <br /> 프로젝트를 진행합니다.
            </span>
          </p>
        </div>
        <div style={box7}></div>
      </div>

      <img
        src={gradientImg}
        style={{
          position: "absolute",
          left: isMobile ? "5vw" : isTablet ? "40vw" : "40vw",
          top: isMobile ? "375vh" : isTablet ? "244vh" : "380vh",
          width: isMobile ? "90vw" : isTablet ? "52vw" : "52vw",
          height: isMobile ? "10vh" : isTablet ? "20vh" : "20vh",
        }}
      />

      <div style={pageGroup}>
        <div>
          <p style={page1}>04</p>
        </div>
        <div>
          <p style={page2}>06</p>
        </div>
        <div style={divider}></div>
      </div>
    </section>
  );
};

export default Process;
