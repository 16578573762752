import React from "react";
import "../static/fonts/font.css";
import { useMediaQuery } from "react-responsive";

const Sidebar = ({ toggleSidebar, activeSection, setActiveSection }) => {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const isTablet = useMediaQuery({ minWidth: 481, maxWidth: 1024 });
  const isBrowser = useMediaQuery({ minWidth: 1025 });

  const handleClick = (sectionId) => {
    const targetSection = document.getElementById(sectionId);

    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
      toggleSidebar();
      setActiveSection(sectionId); // Set the active section when a menu item is clicked
    }
  };

  const sectionBackgroundColors = {
    section1: "#111111",
    section2: "#111111",
    section3: "#F2F3F5",
    section4: "#2A51EC",
    section5: "#FFFFFF",
    section6: "#F2F3F5",
  };

  const sectionTextColors = {
    section1: "#F2F3F5",
    section2: "#F2F3F5",
    section3: "#111111",
    section4: "#F2F3F5",
    section5: "#111111",
    section6: "#111111",
  };

  const ul = {
    listStyleType: "none",
    padding: 0,
  };

  const li = {
    margin: "0.5rem 0",
    cursor: "pointer",
  };

  const sidebarContainer = {
    width: "100.0000vw",
    height: "100vh",
    zIndex: 1,
    top: "8.3333vh",
    backgroundColor: sectionBackgroundColors[activeSection],
  };

  const menu1Text = {
    position: "absolute",
    color: sectionTextColors[activeSection],
    opacity: "0.40",
    width: isMobile ? "5.9375vw" : isTablet ? "5.9375vw" : "5.9375vw",
    height: isMobile ? "4.3519vw" : isTablet ? "4.3519vw" : "7.7778vh",
    left: "9.3750vw",
    top: isTablet ? "5vh" : "7.1296vh",
    fontSize: isMobile ? "12.0833vw" : isTablet ? "5vw" : "2.0833vw",
    fontFamily: "Pretendard Variable",
    fontWeight: isMobile ? "500" : isTablet ? "500" : "100",
    textAlign: "left",
    lineHeight: isMobile ? "4.6875vw" : isTablet ? "4.6875vw" : "8.3333vh",
    ...(activeSection === "section1"
      ? {
          // width: isMobile ? "20.0938vw" : "16.0938vw",
          height: "10.5556vh",
          left: "0.0000vw",
          top: isMobile ? "4vh" : isTablet ? "" : "",
          color: "#F2F3F5",
          opacity: "1.00",
          fontSize: isMobile ? "16.6667vw" : isTablet ? "10vw" : "5.2083vw",
          fontWeight: "900",
          textAlign: "right",
          lineHeight: "9.2593vh",
          borderBottom: isMobile
            ? "1.2604vw solid #F2F3F5"
            : isTablet
            ? "0.5vw solid #F2F3F5"
            : "0.2604vw solid #F2F3F5",
          width: isMobile ? "50vw" : isTablet ? "60vw" : "36.8229vw",
          textIndent: isMobile ? "10vw" : isTablet ? "10vw" : "",
        }
      : {}),
  };

  const menu2Text = {
    position: "absolute",
    color: sectionTextColors[activeSection],
    opacity: "0.40",
    width: "5.9375vw",
    height: isMobile ? "4.3519vw" : isTablet ? "4.3519vw" : "7.7778vh",
    left: "9.3750vw",
    top: isMobile ? "21.7778vh" : isTablet ? "20vh" : "17.7778vh",
    fontSize: isMobile ? "12.0833vw" : isTablet ? "5vw" : "2.0833vw",
    fontFamily: "Pretendard Variable",
    fontWeight: isMobile ? "500" : isTablet ? "500" : "100",
    textAlign: "left",
    lineHeight: isMobile ? "4.6875vw" : isTablet ? "4.6875vw" : "8.3333vh",
    ...(activeSection === "section2"
      ? {
          // width: isMobile ? "20.0938vw" : "16.0938vw",
          height: "10.5556vh",
          left: "0.0000vw",
          top: isMobile ? "16vh" : isTablet ? "15vh" : "15vh",
          color: "#F2F3F5",
          opacity: "1.00",
          fontSize: isMobile ? "16.6667vw" : isTablet ? "10vw" : "5.2083vw",
          fontWeight: "900",
          textAlign: isMobile ? "left" : "right",
          lineHeight: "9.2593vh",
          borderBottom: isMobile
            ? "1.2604vw solid #F2F3F5"
            : isTablet
            ? "0.5vw solid #F2F3F5"
            : "0.2604vw solid #F2F3F5",
          width: isMobile ? "61vw" : isTablet ? "50vw" : "36.8229vw",
          textIndent: isMobile ? "10vw" : isTablet ? "10vw" : "",
        }
      : {}),
  };

  const menu3Text = {
    position: "absolute",
    color: sectionTextColors[activeSection],
    opacity: "0.40",
    width: "5.9375vw",
    height: isMobile ? "4.3519vw" : "7.7778vh",
    left: "9.3750vw",
    top: isMobile ? "35.4259vh" : isTablet ? "35vh" : "28.4259vh",
    fontSize: isMobile ? "12.0833vw" : isTablet ? "5vw" : "2.0833vw",
    fontFamily: "Pretendard Variable",
    fontWeight: isMobile ? "500" : isTablet ? "500" : "100",
    textAlign: "left",
    lineHeight: isMobile ? "4.6875vw" : isTablet ? "4.6875vw" : "8.3333vh",
    ...(activeSection === "section3"
      ? {
          // width: isMobile ? "20.0938vw" : "16.0938vw",
          height: isMobile ? "10.5556vh" : "10.5556vh",
          left: "0.0000vw",
          top: isMobile ? "30vh" : isTablet ? "30vh" : "26vh",
          color: "#2A51EC",
          opacity: "1.00",
          fontSize: isMobile ? "16.6667vw" : isTablet ? "10vw" : "5.2083vw",
          fontWeight: "900",
          textAlign: isMobile ? "left" : "right",
          lineHeight: "9.2593vh",
          borderBottom: isMobile
            ? "1.2604vw solid #2a51ec"
            : isTablet
            ? "0.5vw solid #2a51ec"
            : "0.2604vw solid #2a51ec",
          width: isMobile ? "80vw" : isTablet ? "60vw" : "36.8229vw",
          textIndent: isMobile ? "10vw" : isTablet ? "10vw" : "",
        }
      : {}),
  };

  const menu4Text = {
    position: "absolute",
    color: sectionTextColors[activeSection],
    opacity: "0.40",
    width: "5.9375vw",
    height: isMobile ? "4.3519vw" : "7.7778vh",
    left: "9.3750vw",
    top: isMobile ? "49.0741vh" : isTablet ? "50vh" : "39.0741vh",
    fontSize: isMobile ? "12.0833vw" : isTablet ? "5vw" : "2.0833vw",
    fontFamily: "Pretendard Variable",
    fontWeight: isMobile ? "500" : isTablet ? "500" : "100",
    textAlign: "left",
    lineHeight: isMobile ? "4.6875vw" : isTablet ? "4.6875vw" : "8.3333vh",
    ...(activeSection === "section4"
      ? {
          // width: "16.0938vw",
          height: "10.5556vh",
          left: "0.0000vw",
          top: isMobile ? "44vh" : isTablet ? "45vh" : "37vh",
          color: "#F2F3F5",
          opacity: "1.00",
          fontSize: isMobile ? "16.6667vw" : isTablet ? "10vw" : "5.2083vw",
          fontWeight: "900",
          textAlign: isMobile ? "left" : "right",
          lineHeight: "9.2593vh",
          borderBottom: isMobile
            ? "1.2604vw solid #F2F3F5"
            : isTablet
            ? "0.5vw solid #F2F3F5"
            : "0.2604vw solid #F2F3F5",
          width: isMobile ? "77vw" : isTablet ? "60vw" : "36.8229vw",
          textIndent: isMobile ? "10vw" : isTablet ? "10vw" : "",
        }
      : {}),
  };

  const menu5Text = {
    position: "absolute",
    color: sectionTextColors[activeSection],
    opacity: "0.40",
    width: "5.9375vw",
    height: isMobile ? "4.3519vw" : "7.7778vh",
    left: "9.3750vw",
    top: isMobile ? "63.8148vh" : isTablet ? "65vh" : "49.8148vh",
    fontSize: isMobile ? "12.0833vw" : isTablet ? "5vw" : "2.0833vw",
    fontFamily: "Pretendard Variable",
    fontWeight: isMobile ? "500" : isTablet ? "500" : "100",
    textAlign: "left",
    lineHeight: isMobile ? "4.6875vw" : isTablet ? "4.6875vw" : "8.3333vh",
    ...(activeSection === "section5"
      ? {
          // width: "16.0938vw",
          height: "10.5556vh",
          left: "0.0000vw",
          top: isMobile ? "58vh" : isTablet ? "60vh" : "48vh",
          color: "#2A51EC",
          opacity: "1.00",
          fontSize: isMobile ? "16.6667vw" : isTablet ? "10vw" : "5.2083vw",
          fontWeight: "900",
          textAlign: isMobile ? "left" : "right",
          lineHeight: "9.2593vh",
          borderBottom: isMobile
            ? "1.2604vw solid #2a51ec"
            : isTablet
            ? "0.5vw solid #2a51ec"
            : "0.2604vw solid #2a51ec",
          width: isMobile ? "55vw" : isTablet ? "60vw" : "36.8229vw",
          textIndent: isMobile ? "10vw" : "",
        }
      : {}),
  };

  const menu6Text = {
    position: "absolute",
    color: sectionTextColors[activeSection],
    opacity: "0.40",
    width: "5.9375vw",
    height: isMobile ? "4.3519vw" : "7.7778vh",
    left: "9.3750vw",
    top: isMobile ? "77.4630vh" : isTablet ? "80vh" : "60.4630vh",
    fontSize: isMobile ? "12.0833vw" : isTablet ? "5vw" : "2.0833vw",
    fontFamily: "Pretendard Variable",
    fontWeight: isMobile ? "500" : isTablet ? "500" : "100",
    textAlign: "left",
    lineHeight: isMobile ? "4.6875vw" : isTablet ? "4.6875vw" : "8.3333vh",
    ...(activeSection === "section6"
      ? {
          // width: "16.0938vw",
          height: "10.5556vh",
          left: "0.0000vw",
          top: isMobile ? "72vh" : isTablet ? "75vh" : "59vh",
          color: "#2A51EC",
          opacity: "1.00",
          fontSize: isMobile ? "16.6667vw" : isTablet ? "10vw" : "5.2083vw",
          fontWeight: "900",
          textAlign: isMobile ? "left" : "right",
          lineHeight: "9.2593vh",
          borderBottom: isMobile
            ? "1.2604vw solid #2a51ec"
            : isTablet
            ? "0.5vw solid #2a51ec"
            : "0.2604vw solid #2a51ec",
          width: isMobile ? "75vw" : isTablet ? "60vw" : "36.8229vw",
          textIndent: isMobile ? "10vw" : "",
        }
      : {}),
  };

  const boxStlye = {
    position: "absolute",
    width: "55.5208vw",
    height: "80vh",
    right: "0",
    backgroundColor: "#FFFFFF",
  };

  return (
    <div className="sidebar" style={sidebarContainer}>
      <ul style={ul}>
        <li style={li}>
          <div style={menu1Text} onClick={() => handleClick("section1")}>
            Main
          </div>
        </li>
        <li style={li}>
          <div style={menu2Text} onClick={() => handleClick("section2")}>
            About
          </div>
        </li>
        <li style={li}>
          <div style={menu3Text} onClick={() => handleClick("section3")}>
            Portfolio
          </div>
        </li>
        <li style={li}>
          <div style={menu4Text} onClick={() => handleClick("section4")}>
            Process
          </div>
        </li>
        <li style={li}>
          <div style={menu5Text} onClick={() => handleClick("section5")}>
            Store
          </div>
        </li>
        <li style={li}>
          <div style={menu6Text} onClick={() => handleClick("section6")}>
            Contact
          </div>
        </li>
      </ul>
      {/* <div style={boxStlye}>
            <video controls width="100%" height="100%">
              <source src="your-video-file.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div> */}
    </div>
  );
};

export default Sidebar;
