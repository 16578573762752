// Section.js
import React, { useState } from "react";
import "../static/fonts/font.css";
import { useMediaQuery } from "react-responsive";
import item1 from "../assets/item_1.png";
import item2 from "../assets/item_2.png";
import item3 from "../assets/item_3.png";
import item4 from "../assets/item_4.png";
import danley from "../assets/danley_logo.png";
import tatsuta from "../assets/tatsuta_logo.gif";
import moose from "../assets/MOOSE_logo.png";
import leftIcon from "../assets/leftArrow.png";
import rightIcon from "../assets/rightArrow.png";

const Store = ({ id }) => {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const isTablet = useMediaQuery({ minWidth: 481, maxWidth: 1024 });
  const isBrowser = useMediaQuery({ minWidth: 1025 });

  const sectionStyle = {
    width: "100.0000vw",
    height: isTablet ? "66vh" : "100.0000vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FFFFFF",
    overflowY: "hidden",
  };

  const text1 = {
    position: "absolute",
    width: isMobile ? "" : isTablet ? "" : "2.0833vw",
    height: isMobile ? "1.8519vh" : isTablet ? "1.8519vh" : "1.8519vh",
    left: isMobile ? "46vw" : isTablet ? "57.6042vw" : "57.6042vw",
    top: isMobile ? "411vh" : isTablet ? "290vh" : "430.2778vh",
    opacity: "1.00",
    fontFamily: "Pretendard Variable",
    fontSize: isMobile ? "3.1250vw" : isTablet ? "1.5vw" : "1.0471vw",
    fontWeight: "500",
    textAlign: isMobile ? "center" : "left",
    lineHeight: "4.8438vw",
    color: "#2A51EC",
  };

  const text2 = {
    position: "absolute",
    width: isMobile ? "" : isTablet ? "" : "25.3646vw",
    height: isMobile ? "6.3889vh" : isTablet ? "6.3889vh" : "6.3889vh",
    left: isMobile ? "25vw" : isTablet ? "57.6042vw" : "57.6042vw",
    top: isMobile ? "414vh" : isTablet ? "291vh" : "431.2963vh",
    opacity: "1.00",
    fontFamily: "SCoreDream",
    fontSize: isMobile ? "9.3750vw" : isTablet ? "4vw" : "3.1250vw",
    fontWeight: "900",
    textAlign: isMobile ? "center" : "left",
    lineHeight: "4.8438vw",
    color: "#111111",
  };

  const text3 = {
    position: "absolute",
    width: isMobile ? "82vw" : isTablet ? "" : "35.7604vw",
    height: isMobile ? "5.9259vh" : isTablet ? "5.9259vh" : "5.9259vh",
    left: isMobile ? "9vw" : isTablet ? "57.6042vw" : "57.6042vw",
    top: isMobile ? "423vh" : isTablet ? "297vh" : "444.3519vh",
    opacity: "1.00",
    fontFamily: "Pretendard Variable",
    fontSize: isMobile ? "3.1250vw" : isTablet ? "1.5vw" : "1.2500vw",
    fontWeight: "500",
    fontStyle: "normal",
    textAlign: isMobile ? "center" : "left",
    // lineHeight: "4.8438vw",
    color: "#111111",
    // zIndex: 1,
  };

  const text4 = {
    position: "absolute",
    width: isMobile ? "" : isTablet ? "40.9375vw" : "40.9375vw",
    height: isMobile ? "4.3519vh" : isTablet ? "4.3519vh" : "4.3519vh",
    left: isMobile ? "3vw" : isTablet ? "7.3438vw" : "7.3438vw",
    top: isMobile ? "473vh" : isTablet ? "320vh" : "485.3704vh",
    opacity: "1.00",
    fontFamily: "Pretendard Variable",
    fontSize: isMobile ? "2.8333vw" : isTablet ? "1vw" : "0.8333vw",
    fontWeight: "500",
    textAlign: isMobile ? "center" : "left",
    color: "#A5A5A5",
  };

  const itemStyle = {
    position: "absolute",
    width: isMobile ? "50vw" : isTablet ? "34.1146vw" : "34.1146vw",
    height: isMobile ? "33.3333vh" : isTablet ? "35.5741vh" : "61.5741vh",
    left: isMobile ? "25vw" : isTablet ? "12vw" : "12vw",
    top: isMobile ? "438.5370vh" : isTablet ? "280vh" : "419.5370vh",
    opacity: "1.00",
    alignItems: "center",
  };

  const buttonStyle = {
    position: "absolute",
    width: isMobile ? "15vw" : isTablet ? "9vw" : "6.3542vw",
    height: isMobile ? "7.5vw" : isTablet ? "2.5vh" : "3.7037vh",
    left: isMobile ? "42.7vw" : isTablet ? "57.6042vw" : "57.6042vw",
    top: isMobile ? "430.7407vh" : isTablet ? "302vh" : "455.7407vh",
    opacity: "1.00",
    backgroundColor: "#111111",
    borderRadius: isMobile ? "4vw" : isTablet ? "1.3021vw" : "1.3021vw",
    borderColor: "#111111",
    color: "#FFFFFF",
    borderWidth: isMobile ? "0.0521vw" : isTablet ? "0.0521vw" : "0.0521vw",
    borderStyle: "solid",
    fontFamily: "Pretendard Variable",
    fontSize: isMobile ? "3.1250vw" : isTablet ? "1.5vw" : "0.8333vw",
    fontWeight: isMobile ? "900" : "500",
    lineHeight: "1.4583vw",
    fontStyle: "normal",
  };

  const danleyLogo = {
    position: "absolute",
    width: isMobile ? "25.2500vw" : isTablet ? "10vw" : "6.2500vw",
    height: isMobile ? "2.7778vh" : isTablet ? "2.7778vh" : "2.7778vh",
    left: isMobile ? "7.50vw" : isTablet ? "7.5000vw" : "7.5000vw",
    top: isMobile ? "481vh" : isTablet ? "324vh" : "492.5000vh",
    opacity: "1.00",
  };

  const tatsutaLogo = {
    position: "absolute",
    width: isMobile ? "25.2500vw" : isTablet ? "10vw" : "6.2500vw",
    height: isMobile ? "2.7778vh" : isTablet ? "2.7778vh" : "2.7778vh",
    left: isMobile ? "38vw" : isTablet ? "19vw" : "16.5000vw",
    top: isMobile ? "481vh" : isTablet ? "324vh" : "492.5000vh",
    opacity: "1.00",
  };

  const mooseLogo = {
    position: "absolute",
    width: isMobile ? "25.2500vw" : isTablet ? "10vw" : "6.2500vw",
    height: isMobile ? "2.7778vh" : isTablet ? "2.7778vh" : "2.7778vh",
    left: isMobile ? "" : isTablet ? "30vw" : "25.5000vw",
    right: isMobile ? "7.5000vw" : "",
    top: isMobile ? "481vh" : isTablet ? "324vh" : "492.5000vh",
    opacity: "1.00",
  };

  const pageGroup = {
    position: "absolute",
    width: isMobile ? "18vw" : "5.7292vw",
    height: isMobile ? "18vw" : "5.7292vw",
    left: isMobile ? "80vw" : "92.1875vw",
    top: isMobile ? "490vh" : isTablet ? "324vh" : "485vh",
    backgroundColor: "transparent",
  };

  const page1 = {
    position: "absolute",
    width: isMobile ? "3.5417vw" : "3.5417vw",
    height: isMobile ? "3.5938vw" : "6.2963vh",
    // left: "0.0000vw",
    top: isMobile ? "-3.5vh" : "-4.1667vw",
    opacity: "1.00",
    fontFamily: "Pretendard Variable",
    fontSize: isMobile ? "9.3750vw" : "3.1250vw",
    fontWeight: isMobile ? "900" : "500",
    fontStyle: "normal",
    textAlign: "center",
    lineHeight: "4.8438vw",
    color: "#111111",
  };

  const page2 = {
    position: "absolute",
    width: isMobile ? "2.3958vw" : "3.5417vw",
    height: isMobile ? "2.4479vw" : "6.2963vh",
    left: isMobile ? "9vw" : "2.4vw",
    top: isMobile ? "3vh" : "0vh",
    opacity: "0.20",
    fontFamily: "Pretendard Variable",
    fontSize: isMobile ? "6.2500vw" : "2.0833vw",
    fontWeight: isMobile ? "900" : "500",
    fontStyle: "normal",
    textAlign: "center",
    lineHeight: "4.8438vw",
    color: "#111111",
  };

  const divider = {
    position: "absolute",
    width: isMobile ? "10vw" : "2.6042vw",
    height: isMobile ? "0.1042vw" : "0.1042vw",
    left: isMobile ? "5vw" : "2vw",
    top: isMobile ? "10vw" : "3vw",
    opacity: "1.00",
    backgroundColor: "#111111",
    transform: "rotate(-45deg)",
  };

  const leftIconStyle = {
    cursor: "pointer",
    width: "21px",
    height: "21px",
    position: "absolute",
    top: isMobile ? "453vh" : isTablet ? "297vh" : "448.2407vh",
    left: isMobile ? "4vw" : isTablet ? "4vw" : "4.0000vw",
  };

  const rightIconStyle = {
    cursor: "pointer",
    width: "21px",
    height: "21px",
    position: "absolute",
    top: isMobile ? "453vh" : isTablet ? "297vh" : "448.2407vh",
    right: isMobile ? "4vw" : isTablet ? "" : "",
  };

  const items = [
    {
      id: 1,
      name: "Item 1",
      imageUrl: item1,
    },
    {
      id: 2,
      name: "Item 2",
      imageUrl: item2,
    },
    {
      id: 3,
      name: "Item 3",
      imageUrl: item3,
    },
    {
      id: 4,
      name: "Item 4",
      imageUrl: item4,
    },
    // Add more items as needed
  ];

  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const currentItem = items[currentItemIndex];

  const nextItem = () => {
    setCurrentItemIndex((prevIndex) => (prevIndex + 1) % items.length);
  };

  const prevItem = () => {
    setCurrentItemIndex((prevIndex) =>
      prevIndex === 0 ? items.length - 1 : prevIndex - 1
    );
  };

  const handleClick = () => {
    const targetSection = document.getElementById("section6");

    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <section id={id} style={sectionStyle}>
      <div>
        <img
          src={leftIcon}
          alt="Left Icon"
          style={leftIconStyle}
          onClick={prevItem}
        />
        <img
          src={currentItem.imageUrl}
          alt={currentItem.name}
          style={itemStyle}
        />
        <img
          src={rightIcon}
          alt="Right Icon"
          style={rightIconStyle}
          onClick={nextItem}
        />
      </div>
      <div>
        <p style={text1}>Store</p>
      </div>
      <div>
        <p style={text2}>Rui Sound</p>
      </div>
      <div>
        <p style={text3}>
          공간을 가득 채우는 풍부한 소리, 보다 나은 시스템과 사운드를 위한{" "}
          <br /> 국내 독점 수입 · 유통을 통해 최고의 서비스를 제공합니다.
        </p>
      </div>
      <button style={buttonStyle} onClick={handleClick}>
        Buy
      </button>
      <div>
        <p style={text4}>
          * 제품 구매 희망 시 견적 문의를 통해 문의 부탁드립니다. <br /> * 각
          제품의 본사 홈페이지 로고를 클릭하면 더욱 다양한 제품을 만나보실 수
          있습니다.{" "}
        </p>
      </div>
      <a
        href="https://danleysoundlabs.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={danley} alt="rui" style={danleyLogo} />
      </a>
      <a
        href="https://www.tatsuta.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={tatsuta} alt="rui" style={tatsutaLogo} />
      </a>
      <a
        href="https://moosesound.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={moose} alt="rui" style={mooseLogo} />
      </a>
      <div style={pageGroup}>
        <div>
          <p style={page1}>05</p>
        </div>
        <div>
          <p style={page2}>06</p>
        </div>
        <div style={divider}></div>
      </div>
    </section>
  );
};

export default Store;
