import React from "react";
import { useMediaQuery } from "react-responsive";

const Scrollbar = ({ activeSection, setActiveSection }) => {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const isTablet = useMediaQuery({ minWidth: 481, maxWidth: 1024 });
  const isBrowser = useMediaQuery({ minWidth: 1025 });

  const handleSectionClick = (section) => {
    setActiveSection(section);

    const targetSection = document.getElementById(section);
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollbarStyle = {
    position: "fixed",
    width: "0.2000vw",
    height: "44.2593vh",
    left: "96.6667vw",
    top: "27.8704vh",
    opacity: "1.00",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  };

  const scrollbarSection = {
    flex: "1",
    textAlign: "center",
    cursor: "pointer",
    margin: "0.2604vw 0",
    border: "0.0521vw solid transparent",
    position: "relative",
    borderRadius: "2.6042vw",
  };

  const separator = {
    content: '""',
    position: "relative",
    top: 0,
    right: 0,
    bottom: 0,
    width: "0.0521vw",
    backgroundColor: "#111111",
    opacity: "0.15",
    zIndex: 1,
  };

  const sections = [
    "section1",
    "section2",
    "section3",
    "section4",
    "section5",
    "section6",
  ];

  return (
    <>
      {isMobile || isTablet ? (
        <div className="scrollbar" style={scrollbarStyle}></div>
      ) : (
        <div className="scrollbar" style={scrollbarStyle}>
          {sections.map((section, index) => (
            <div
              key={section}
              onClick={() => handleSectionClick(section)}
              style={{
                ...scrollbarSection,
                backgroundColor:
                  (section === "section3" ||
                    section === "section5" ||
                    section === "section6") &&
                  section === activeSection
                    ? "#2A51EC"
                    : (section === "section1" ||section === "section2" || section === "section4") &&
                      section === activeSection
                    ? "#FFFFFF"
                    : "#A9A9A9",
              }}
            >
              <div style={separator}></div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default Scrollbar;
