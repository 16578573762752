import React from "react";
import "../static/fonts/font.css";
import checkIcon from "../assets/check_icon.png";
import { useMediaQuery } from "react-responsive";

const Modal = ({ message, onClose }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const modalStyle = {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
    zIndex: 1000,
  };

  const contentStyle = {
    position: "absolute",
    width: isMobile ? "80vw" : "25vw",
    height: "16.6667vh",
    borderRadius: isMobile ? "3vw" : "0.6250vw",
    background: "#f2f3f5",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  };

  const textStyle = {
    fontFamily: "Pretendard Variable",
    fontSize: isMobile ? "4vw" : "1.0417vw",
    fontWeight: isMobile ? "700 " : "500",
    textAlign: "center",
    // lineHeight: "1.5",
  };

  const checkIconStyle = {
    position: "absolute",
    top: "2.3148vh",
    width: isMobile ? "8vw" : "1.5625vw",
    height: isMobile ? "4vh" : "2.7778vh",
  };

  const buttonStyle = {
    position: "absolute",
    width: isMobile ? "80vw" : "25vw",
    height: "4.4444vh",
    bottom: "0",
    background: "#2a51ec",
    borderRadius: isMobile
      ? "0.0000vw 0.0000vw 3vw 3vw"
      : "0.0000vw 0.0000vw 0.6250vw 0.6250vw", // Adjust border radius
    fontFamily: "Pretendard Variable",
    fontSize: isMobile ? "4vw" : "1.0417vw",
    fontWeight: isMobile ? "900 " : "500",
    color: "#F2F3F5",
    border: "none",
  };

  return (
    <div style={modalStyle}>
      <div style={contentStyle}>
        <img src={checkIcon} alt="check" style={checkIconStyle} />
        <p style={textStyle}>{message}</p>
        <button onClick={onClose} style={buttonStyle}>
          확인
        </button>
      </div>
    </div>
  );
};

export default Modal;
