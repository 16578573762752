import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";

const MenuIcon = ({ toggleSidebar, activeSection }) => {
  const isMobile = useMediaQuery({ maxWidth: 600 });
  const isTablet = useMediaQuery({ minWidth: 601, maxWidth: 1200 });
  const isBrowser = useMediaQuery({ minWidth: 1201 });

  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
    toggleSidebar();
  };

  // Add an effect to listen for changes in the activeSection
  useEffect(() => {
    setIsOpen(false);
  }, [activeSection]);

  const iconColors = {
    section1: "#F2F3F5",
    section2: "#F2F3F5",
    section3: "#2A51EC",
    section4: "#F2F3F5",
    section5: "#2A51EC",
    section6: "#2A51EC",
  };

  const menuIconStyle = {
    position: "relative",
    left: isMobile ? "5vw" : isTablet ? "5vw" : "5vw",
    top: "0vh",
    opacity: "1.00",
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
  };

  const barStyle = {
    width: isMobile ? "4.6875vw" : isTablet ? "3vw" : "1.5625vw",
    height: isMobile ? "0.5vw" : isTablet ? "0.2563vw" : "0.1563vw",
    backgroundColor: iconColors[activeSection],
    margin: isMobile ? "0.5vw 0" : isTablet ? "0.3583vw 0" : "0.2083vw 0", // Larger margin for mobile
    transition: "0.4s",
  };

  const secondBarStyle = {
    ...barStyle,
    width: isMobile ? "2.3438vw" : isTablet ? "1.5vw" : "0.7813vw",
    marginLeft: isOpen ? "0" : isMobile ? "auto" : isTablet ? "auto" : "auto",
  };

  return (
    <div>
      <div style={menuIconStyle} onClick={handleClick}>
        <div style={barStyle}></div>
        <div style={secondBarStyle}></div>
      </div>
    </div>
  );
};

export default MenuIcon;
