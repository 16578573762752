// Section.js
import React from "react";
import { useMediaQuery } from "react-responsive";
import "../static/fonts/font.css";
import insta from "../assets/instagram.png";
import logo from "../assets/rulenm_logo_white.png";

const DesktopFooter = ({ id }) => {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const isTablet = useMediaQuery({ minWidth: 481, maxWidth: 1024 });
  const isBrowser = useMediaQuery({ minWidth: 1025 });

  const sectionStyle = {
    width: "100.0000vw",
    height: "27.7778vh",
    display: "grid",
    gridTemplateColumns: "repeat(5, 1fr)",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#111111",
    color: "#FFFFFF",
  };

  const gridStyle = {
    flex: 1, // Each section takes equal width
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Pretendard Variable",
    fontSize: isTablet ? "1.25vw" : "0.6250vw",
    fontWeight: "500",
    textAlign: isTablet ? "center" : "left",
    lineHeight: isTablet ? "2.5vw" : "1.2500vw",
    color: "#C1C1C1",
  };

  const snsStyle = {
    position: "absolute",
    width: isTablet ? "3vw" : "1.2500vw",
    height: isTablet ? "3vw" : "1.2500vw",
    left: "89.3750vw",
    opacity: "1.00",
  };

  const logoStyle = {
    position: "absolute",
    width: isTablet ? "12vw" : "4.6875vw",
    height: isTablet ? "4vw" : "1.5625vw",
    left: isTablet ? "4vw" : "9.3750vw",
    opacity: "1.00",
  };

  return (
    <section id={id} style={sectionStyle}>
      <div style={gridStyle}>
        <img src={logo} alt="rui" style={logoStyle} />
      </div>
      <div style={gridStyle}>
        <p>제휴 제안 &nbsp; &nbsp; &nbsp; &nbsp;개인정보처리방침</p>
        <p>
          (주)루이이엔엠 <br /> 경기 고양시 일산동구 장대길 42-76 <br />{" "}
          대표이사: 곽호재 <br /> 사업자 등록번호: 128-86-18587 <br /> Email:
          email_00@email.com <br /> 통신판매업 신고번호: 경기고양 제 2012-000호
        </p>
      </div>
      <div style={gridStyle}>
        <p>고객센터 (10:00-17:30, 월~금)</p>
        <p>02-123-4567</p>
      </div>
      <div style={gridStyle}>
        <p>From planning to progressing, one stop</p>
      </div>
      <div style={gridStyle}>
        <a
          href="https://www.instagram.com/ruienm_kor/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={insta} alt="insta" style={snsStyle} />
        </a>
      </div>
    </section>
  );
};

const MobileFooter = ({ id }) => {
  const sectionStyle = {
    width: "100.0000vw",
    height: "55.5556vh",
    display: "grid",
    gridTemplateRows: "repeat(5, 1fr)",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#111111",
    color: "#FFFFFF",
  };

  const gridStyle = {
    flex: 1, // Each section takes equal width
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Pretendard Variable",
    fontSize: "2vw",
    fontWeight: "500",
    textAlign: "center",
    lineHeight: "2.2500vh",
    color: "#C1C1C1",
    marginTop: "1vh",
  };

  const snsStyle = {
    position: "relative",
    width: "8vw",
    height: "8vw",
    opacity: "1.00",
    marginBottom: "2.7778vh",
  };

  const logoStyle = {
    position: "relative",
    width: "30vw",
    height: "10vw",
    opacity: "1.00",
    marginTop: "2.7778vh",
  };

  return (
    <section id={id} style={sectionStyle}>
      <div style={gridStyle}>
        <img src={logo} alt="rui" style={logoStyle} />
      </div>
      <div style={gridStyle}>
        <p>제휴 제안 &nbsp; &nbsp; &nbsp; &nbsp;개인정보처리방침</p>
        <p>
          (주)루이이엔엠 <br /> 서울특별시 마포구 월드컵북로6길 82 3층 <br />{" "}
          대표이사: 곽호재 <br /> Email: ruienminfo@naver.com <br /> 통신판매업
          신고번호: 경기고양 제 2012-000호
        </p>
      </div>
      <div style={gridStyle}>
        <p>고객센터 (10:00-17:30, 월~금)</p>
        <p>070-4458-3351</p>
      </div>
      <div style={gridStyle}>
        <p>From planning to progressing, one stop</p>
      </div>
      <div style={gridStyle}>
        <a
          href="https://www.instagram.com/ruienm_kor/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={insta} alt="insta" style={snsStyle} />
        </a>
      </div>
    </section>
  );
};

const Footer = ({ id }) => {
  const isMobile = useMediaQuery({ maxWidth: 480 });
  const isTablet = useMediaQuery({ minWidth: 481, maxWidth: 1024 });
  const isBrowser = useMediaQuery({ minWidth: 1025 });

  return (
    <section id={id}>{isMobile ? <MobileFooter /> : <DesktopFooter />}</section>
  );
};

export default Footer;
